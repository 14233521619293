import dayjs from 'dayjs';
import { CellProps } from 'react-table';

import Gateway from 'types/gateway';

import { useStartCloudGateway } from 'gateways/hooks/useStartCloudGateway';

import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';
import { useRetryCloudGateway } from 'gateways/hooks/useRetryCloudGateway';

export function GatewaysTableStatusCell({ row }: CellProps<Gateway>) {
  const gateway = row.original;

  const { mutate: restartTrial, isLoading: isRestarting } =
    useStartCloudGateway();

  const { mutate: retryCreate, isLoading: isRetrying } = useRetryCloudGateway();

  function handleRestartClick() {
    restartTrial(gateway.id);
  }

  function handleRetryClick() {
    retryCreate(gateway.id);
  }

  return (
    <div className="columns valign-center">
      <StatusIndicator status={gateway.status} />

      {gateway.status === 'online' && gateway.stops_automatically_at && (
        <Text inline>
          <Icon name="timer" />
          <span>
            {dayjs(gateway.stops_automatically_at).fromNow(true)} remaining
          </span>
        </Text>
      )}

      {gateway.status === 'pending' && (
        <Button
          size="xsmall"
          variant="secondary"
          onClick={handleRetryClick}
          loading={isRetrying}
        >
          <Icon name="rotate" />
          <span>Restart</span>
        </Button>
      )}

      {gateway.isTrial && gateway.status === 'offline' && (
        <Button
          size="xsmall"
          variant="secondary"
          loading={isRestarting}
          onClick={handleRestartClick}
        >
          <Icon name="rotate" />
          <span>Restart</span>
        </Button>
      )}
    </div>
  );
}
