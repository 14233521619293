import classnames from 'classnames/bind';

import Camera from 'types/camera';

import { useDeleteReferencePipelines } from 'cameras/hooks/useDeleteReferencePipelines';
import { usePipelines } from 'pipelines/hooks/usePipelines';

import { AppLink } from 'components/AppLink/AppLink';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import styles from './CameraDetailSettings.module.scss';

const c = classnames.bind(styles);

type UniversalBridgePipelinesProps = {
  camera: Camera;
};

export function UniversalBridgePipelines({
  camera,
}: UniversalBridgePipelinesProps) {
  const isEnabled = Boolean(camera.reference_pipeline_ids.length);

  const { data: pipelines, isInitialLoading: isLoading } = usePipelines(
    {
      pipeline_ids: camera.reference_pipeline_ids,
    },
    {
      enabled: isEnabled,
      keepPreviousData: true,
    }
  );

  const { mutate: deleteReferencePipelines } =
    useDeleteReferencePipelines(camera);

  function handleDeleteReferencePipeline(pipelineID?: string) {
    if (pipelineID) {
      deleteReferencePipelines([pipelineID]);
    }
  }

  if (!pipelines && isLoading) {
    return (
      <SkeletonList
        component={<UniversalBridgePipelineSkeletonItem />}
        min={2}
        max={5}
      />
    );
  }

  if (!isEnabled) {
    return null;
  }

  return (
    <div className={c('list')}>
      {pipelines?.data.map(({ id, name }) => (
        <div className={c('item')} key={id}>
          <Heading level="4" asChild>
            <AppLink to={`/design/pipelines/${id}`}>{name}</AppLink>
          </Heading>
          <IconButton
            icon="cancel"
            label="Remove pipeline"
            onClick={() => handleDeleteReferencePipeline(id)}
          />
        </div>
      ))}
    </div>
  );
}

function UniversalBridgePipelineSkeletonItem() {
  return (
    <div
      className={c('item', 'loading', 'skeleton')}
      aria-label="Loading reference pipelines..."
    >
      <Text className={c('skeleton-text')}>Loading pipeline...</Text>
      <Text className={c('skeleton-text')} />
    </div>
  );
}
