import classNames from 'classnames/bind';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AdminRoute, NavRoute } from 'application/types/routes';

import { useIsLumeoAdmin } from 'hooks/useAuth';

import { CreateMarketplaceModelView } from 'analytics-library/views/CreateModelView/CreateMarketplaceModelView';
import { Marketplace } from 'analytics-library/views/Marketplace/Marketplace';
import { MarketplaceModelDetailContainer } from 'analytics-library/views/MarketplaceModelDetail/MarketplaceModelDetailContainer';
import { NotFound } from 'views/NotFound/NotFound';

import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarLink } from 'components/Sidebar/SidebarLink';
import {
  Step1,
  Step2,
  Step3,
} from 'analytics-library/components/MultiStepForm';

import styles from './Admin.module.scss';

const c = classNames.bind(styles);

export function Admin() {
  const isAdmin = useIsLumeoAdmin();

  if (!isAdmin) {
    return <NotFound />;
  }

  return (
    <div className={c('admin')}>
      <Sidebar>
        <SidebarLink
          to={`/${NavRoute.ADMIN}/${AdminRoute.MARKETPLACE_MODELS}`}
          icon="model"
        >
          Marketplace models
        </SidebarLink>
      </Sidebar>
      <Routes>
        <Route index element={<Navigate to="marketplace-models" replace />} />

        <Route path="marketplace-models/*" element={<Marketplace />} />
        <Route
          path="marketplace-models/:modelID"
          element={<MarketplaceModelDetailContainer />}
        />
        <Route
          path="marketplace-models/new/*"
          element={<CreateMarketplaceModelView />}
        >
          <Route index element={<Step1 />} />
          <Route path="parameters" element={<Step2 />} />
          <Route path="inference-config" element={<Step3 />} />
        </Route>
      </Routes>
    </div>
  );
}
