import classNames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Skeleton } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useParams } from 'react-router-dom';

import Stream from 'types/stream';
import { ApplicationParams } from 'application/types/application_params';
import { UseStreamsFilterParams } from 'streams/hooks/useStreams';

import { API_BASE_URL } from 'services/api';
import { useCreateSharedStream } from 'streams/hooks/useCreateSharedStream';
import { useFile } from 'files/hooks/useFile';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { CodeBlock } from 'components/CodeBlock/CodeBlock';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import {
  EXPIRATION_OPTIONS,
  ExpirationOption,
  ExpirationSelect,
} from 'components/ExpirationSelect/ExpirationSelect';
import { Field } from 'components/Field/Field';
import { Icon } from 'components/Icon/Icon';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';
import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import styles from './CreateSharedStreamDrawer.module.scss';

const c = classNames.bind(styles);

type CreateSharedStreamFieldValues = {
  name: string;
  expiration: ExpirationOption;
  stream?: Stream;
};

type CreateSharedStreamDrawerProps = DrawerProps & {
  defaultStream?: Stream;
  params?: UseStreamsFilterParams;
};

export function CreateSharedStreamDrawer({
  open,
  onClose,
  defaultStream,
  params,
}: CreateSharedStreamDrawerProps) {
  const { applicationID } = useParams<ApplicationParams>();

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<CreateSharedStreamFieldValues>({
    defaultValues: {
      name: '',
      expiration: EXPIRATION_OPTIONS['30 days'],
      stream: defaultStream,
    },
  });

  const {
    data: sharedStream,
    mutate: createSharedStream,
    isLoading: isCreatingSharedStream,
    reset: resetSharedStream,
  } = useCreateSharedStream({
    onSuccess() {
      reset(getValues());
    },
  });

  const stream = watch('stream');

  const { data: file, isInitialLoading: isLoadingFile } = useFile(
    stream?.snapshot_file_id
  );

  const { copied: hasCopiedUrl, copy: copyUrl } = useClipboard({
    timeout: 2000,
  });
  const { copied: hasCopiedIframe, copy: copyIframe } = useClipboard({
    timeout: 2000,
  });

  function handleCreateSharedStream({
    name,
    stream,
    expiration,
  }: CreateSharedStreamFieldValues) {
    if (!stream) {
      return;
    }

    createSharedStream({
      name,
      stream_id: stream.id,
      expires_at: expiration.value(),
    });
  }

  function handleClose() {
    reset({
      name: '',
      expiration: EXPIRATION_OPTIONS['30 days'],
      stream: defaultStream,
    });
    resetSharedStream();
    onClose?.();
  }

  const environment =
    API_BASE_URL.match(/api-(dev|staging)\.lumeo\.com/)?.[1] || 'console';

  const url = `https://${environment}.lumeo.com/webrtc.html?applicationId=${applicationID}&streamId=${stream?.id}&token=${sharedStream?.token}`;
  const iframe = `<iframe src="${url}" sandbox="allow-scripts allow-same-origin"></iframe>`;

  return (
    <Drawer
      title="Add shared stream"
      open={open}
      onClose={handleClose}
      footer={
        <ButtonGroup align="end">
          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}
          {!Boolean(sharedStream) || isDirty ? (
            <Button
              form="create-shared-stream-form"
              variant="primary"
              type="submit"
              loading={isCreatingSharedStream}
            >
              Create shared stream URL
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          )}
        </ButtonGroup>
      }
    >
      <form
        id="create-shared-stream-form"
        onSubmit={handleSubmit(handleCreateSharedStream)}
      >
        <Field label="Name" error={errors.name}>
          <Input {...register('name', { required: 'Please choose a name.' })} />
        </Field>
        <Field label="Expiration" error={errors.expiration}>
          <Controller
            name="expiration"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ExpirationSelect value={value} onChange={onChange} />
            )}
            rules={{ required: 'Please choose an expiration.' }}
          />
        </Field>
        <Field label="Stream" error={errors.stream}>
          <Controller
            name="stream"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StreamSelect
                value={value}
                onChange={onChange}
                queryFilters={params}
              />
            )}
            rules={{ required: 'Please choose a stream.' }}
          />
        </Field>
        {stream && !Boolean(stream.snapshot_file_id) && (
          <Text>No preview image available for this stream.</Text>
        )}
      </form>

      {sharedStream && stream && (
        <div className={c('success-message', 'theme success')}>
          <div className={c('success-message-title')}>
            <Icon name="check-circle" />
            <Text inline>New shared stream URL generated.</Text>
          </div>
          <CodeBlock>
            <p>{url}</p>
          </CodeBlock>
          <ButtonGroup>
            <Tooltip
              side="left"
              content="Copied to clipboard!"
              open={hasCopiedUrl}
            >
              <Button
                onClick={() => copyUrl(url)}
                variant="secondary"
                size="small"
              >
                <Icon name="clipboard" />
                <span>Copy URL</span>
              </Button>
            </Tooltip>
            <Tooltip
              side="right"
              content="Copied to clipboard!"
              open={hasCopiedIframe}
            >
              <Button
                onClick={() => copyIframe(iframe)}
                variant="secondary"
                size="small"
              >
                <Icon name="clipboard" />
                <span>Copy as embeddable iframe</span>
              </Button>
            </Tooltip>
          </ButtonGroup>

          <Text type="paragraph">
            Make sure to copy this URL or iframe code as you will not be able to
            see it again.
            <br />
            {sharedStream.expires_at && (
              <>
                The shared stream URL expires{' '}
                {DateTime.fromISO(sharedStream?.expires_at).toLocaleString(
                  DateTime.DATETIME_MED
                )}
                .
              </>
            )}
          </Text>
        </div>
      )}

      {stream && stream.snapshot_file_id && (
        <>
          {isLoadingFile ? (
            <Skeleton className={c('skeleton')} width="100%" />
          ) : (
            <img className={c('preview')} alt="" src={file?.data_url} />
          )}
        </>
      )}
    </Drawer>
  );
}
