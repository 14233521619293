import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

export function ConfirmDeleteDialog({
  confirmButtonText = 'Yes, delete',
  ...props
}: Omit<ConfirmDialogProps, 'intent'>) {
  return (
    <ConfirmDialog
      {...props}
      confirmButtonText={confirmButtonText}
      intent="danger"
    />
  );
}
