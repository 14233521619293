import React from 'react';
import classNames from 'classnames/bind';
import { Input, Loader } from '@mantine/core';

import { OffsetPaginated } from 'types/pagination';
import { Tag } from 'types/tag';

import { useTags } from 'tags/hooks/useTags';
import { useTagsAncestors } from 'tags/hooks/useTagsAncestors';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeSearchProps = {
  onResultsChange: (data?: Tag[]) => void;
};

export function TagsTreeSearch({ onResultsChange }: TagsTreeSearchProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const timeoutRef = React.useRef<number>();

  const [value, setValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const {
    data,
    isInitialLoading: isLoadingResults,
    isSuccess,
    error,
  } = useTags({
    params: {
      tag_names: [searchTerm],
      limit: 100,
    },
    keepPreviousData: true,
    enabled: Boolean(searchTerm?.trim()),
  });

  const {
    data: ancestors,
    isLoading: isLoadingAncestors,
    error: ancestorsError,
  } = useTagsAncestors(data, {
    enabled: isSuccess,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (
      !searchTerm.trim() ||
      !data ||
      ancestors.some((x) => !x) ||
      isLoadingAncestors
    ) {
      return;
    }

    const combined = [
      ...data.data,
      ...(ancestors as OffsetPaginated<Tag>[]).flatMap(({ data }) => data),
    ];

    const uniqueTags = [
      ...new Map(combined.map((tag) => [tag.id, tag])).values(),
    ];

    onResultsChange(uniqueTags);
  }, [searchTerm, data, ancestors, isLoadingAncestors, onResultsChange]);

  React.useEffect(() => {
    if (!searchTerm.trim()) {
      onResultsChange(undefined);
    }
  }, [searchTerm, onResultsChange]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    window.clearTimeout(timeoutRef.current);
    setValue(event.target.value);

    timeoutRef.current = window.setTimeout(() => {
      setSearchTerm(event.target.value.trim());
    }, 250);
  }

  return (
    <label className={c('search')}>
      <Input
        aria-label="Search lables"
        className={c('search-input')}
        mb="sm"
        variant="search"
        leftSection={<Icon name="search" />}
        rightSection={
          (isLoadingResults || isLoadingAncestors) && <Loader size="sm" />
        }
        type="search"
        placeholder="Search..."
        size="sm"
        value={value}
        onChange={handleChange}
        ref={inputRef}
      />

      {(error || ancestorsError) && (
        <Text intent="danger" size="xsmall">
          {error?.message ?? ancestorsError?.error?.message}
        </Text>
      )}
    </label>
  );
}
