import classnames from 'classnames/bind';

import { useAccount } from 'hooks/api/useAccount';
import { useAuthentication } from 'hooks/api/useAuthentication';

import { NotFound } from 'views/NotFound/NotFound';

import * as PageLayout from 'components/PageLayout';
import { Box } from 'components/Box/Box';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';

import { ChangePasswordForm } from 'account/components/ChangePasswordForm/ChangePasswordForm';

import styles from '../Settings.module.scss';

const c = classnames.bind(styles);

export type UpdateAccountParams = {
  name?: string;
};

export function PasswordSettings() {
  const { data: account, isLoading } = useAccount();
  const { isLoading: isAuthenticating } = useAuthentication();

  if (isLoading || isAuthenticating) {
    return <Loader text="Loading your account settings..." />;
  }

  if (!account) {
    return <NotFound />;
  }

  return (
    <PageLayout.Root size="small">
      <PageLayout.Container>
        <div className={c('wrap', 'settings')}>
          <Box variant="secondary">
            <Heading level="3">Password</Heading>
            <ChangePasswordForm account={account} />
          </Box>
        </div>
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
