import { filesize } from 'filesize';

import { getPropertyValue } from 'types/node';

import { DisplayerProps } from '../controls/input';

export function FileSizeDisplayer({ node, property }: DisplayerProps) {
  const value = getPropertyValue(node.data, property);

  return <strong>{filesize(Number(value), { base: 2 })}</strong>;
}
