import classNames from 'classnames/bind';
import {
  Autocomplete,
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  OptionsFilter,
} from '@mantine/core';
import { matchSorter } from 'match-sorter';

import { parseAccessor } from 'pipelines/services/condition_parser';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { compareValues } from './services/sort_accessor_suggestions';
import { type ConditionInputSuggestion } from './types';
import { useConditionInputState } from './ConditionInput';
import styles from './ConditionInput.module.scss';

const c = classNames.bind(styles);

export type AccessorInputProps = {
  id: string;
  path: number[];
  value: string;
  error?: string;
};

export function AccessorInput({ id, path, value, error }: AccessorInputProps) {
  const { suggestions, updateCondition } = useConditionInputState();

  function renderAccessorInputOption({
    option: { value, label },
  }: ComboboxLikeRenderOptionInput<ConditionInputSuggestion>) {
    const { description } = suggestions[value];

    return (
      <div className={c('suggestion-wrap')}>
        <Icon name="tag" size="small" />
        <Text asChild>
          <strong className={c('suggestion-label')}>{label ?? value}</strong>
        </Text>
        {description && (
          <Text
            className={c('suggestion-description')}
            type="paragraph"
            size="xxsmall"
          >
            {description}
          </Text>
        )}
      </div>
    );
  }

  const filterOptions: OptionsFilter = ({ options, search }) => {
    const filtered = matchSorter(options as ComboboxItem[], search, {
      keys: [
        (item) => item.value,
        (item) => suggestions[item.value]?.description ?? '',
      ],
    });

    filtered.sort((a, b) => {
      const orderA = suggestions[a.value]?.order;
      const orderB = suggestions[b.value]?.order;

      if (orderA !== undefined && orderB !== undefined) {
        if (orderA === orderB) {
          return compareValues(a.value, b.value);
        }

        return orderA - orderB;
      }

      if (orderA !== undefined) {
        return -1;
      }

      if (orderB !== undefined) {
        return 1;
      }

      return compareValues(a.value, b.value);
    });

    return filtered;
  };

  function handleChange(newValue: string) {
    const newAccessor = newValue ?? '';

    if (newAccessor === '') {
      updateCondition(path, 'accessor', {
        text: newAccessor,
        error: 'Accessor cannot be empty.',
      });
      return;
    }

    // Propagate changes
    try {
      parseAccessor(newAccessor);
      updateCondition(path, 'accessor', { text: newAccessor });
    } catch (err) {
      let error = (err as Error).message;
      if (error.includes('Partial match')) {
        error = 'Invalid accessor format.';
      }
      updateCondition(path, 'accessor', { text: newAccessor, error });
    }
  }

  return (
    <Autocomplete
      id={id}
      classNames={{
        input: c('input'),
        option: c('suggestion'),
        dropdown: c('suggestions'),
      }}
      filter={filterOptions}
      data={Object.keys(suggestions)}
      defaultValue={value}
      onChange={handleChange}
      renderOption={renderAccessorInputOption}
      error={Boolean(error)}
      size="sm"
      rightSection={
        error && (
          <Tooltip content={error}>
            <Icon name="error" className="theme danger" />
          </Tooltip>
        )
      }
    />
  );
}
