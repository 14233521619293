import classNames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useClipboard } from '@mantine/hooks';
import { useIntercom } from 'react-use-intercom';

import { addNotification } from 'services/notification';
import { useAPI } from 'hooks/api/useAPI';
import { useCreateAccessToken } from 'hooks/api/useAccessTokens';
import { useCreateCloudGateway } from 'gateways/hooks/useCreateCloudGateway';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useHasAccess } from 'hooks/useHasAccess';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';

import * as List from 'components/List';
import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import {
  EXPIRATION_OPTIONS,
  ExpirationOption,
  ExpirationSelect,
} from 'components/ExpirationSelect/ExpirationSelect';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Field } from 'components/Field/Field';
import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { TerminalCommands } from 'components/TerminalCommands/TerminalCommands';
import { Text } from 'components/Text/Text';
import { TokenSuccessMessage } from 'settings/components/TokenSuccessMessage/TokenSuccessMessage';
import { Tooltip } from 'components/Tooltip/Tooltip';

import LumeoCloudGatewayImg from 'gateways/images/lumeo-cloud-gateway.svg';

import styles from './AddGateway.module.scss';

const c = classNames.bind(styles);

type GatewayProvisioningTokenFieldValues = {
  name: string;
  expiration: ExpirationOption;
};

export type AddGatewayProps = {
  onSuccess?: () => void;
  /**
   * e.g. to close a surrounding drawer
   */
  onLinkClick?: () => void;
};

export function AddGateway({ onSuccess, onLinkClick }: AddGatewayProps) {
  const { show } = useIntercom();

  const { copied: isCopied, copy } = useClipboard({ timeout: 2000 });

  const [hasAccess] = useHasAccess();

  const { data: organization } = useCurrentOrganization();
  const { data: usageStatistics } = useUsageStatistics();
  const { data: currentPlan, isStarterPlan } = useCurrentPlan();
  const { applicationID } = useAPI();

  const expiration = EXPIRATION_OPTIONS['1 day'];

  const {
    control,
    resetField,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<GatewayProvisioningTokenFieldValues>({
    defaultValues: { expiration },
  });

  const {
    data: gatewayProvisioningToken,
    mutate: createGatewayProvisioningToken,
    isLoading: isCreatingGatewayProvisioningToken,
  } = useCreateAccessToken({
    onSuccess() {
      resetField('name');
    },
  });

  const { mutate: createCloudGateway, isLoading } = useCreateCloudGateway({
    onSuccess() {
      addNotification({
        title: 'Cloud gateway has been created',
        message: 'Please wait until the cloud gateway is ready for use.',
        variant: 'success',
        icon: 'check-circle',
        timed: 30 * 1000,
      });
      onSuccess?.();
    },
  });

  const cannotAddGateway =
    organization &&
    usageStatistics &&
    currentPlan?.gateway_limit &&
    usageStatistics.gateway_count >= currentPlan.gateway_limit;

  const canAddTrialCloudGateway =
    !cannotAddGateway &&
    usageStatistics &&
    usageStatistics.cloud_gateway_count <= 0;

  function onSubmit({
    name,
    expiration: { value },
  }: GatewayProvisioningTokenFieldValues) {
    createGatewayProvisioningToken({
      name,
      type: 'gateway_provisioning',
      expires_at: value(),
    });
  }

  function handleStartTrialClick() {
    createCloudGateway({ name: 'Trial Cloud Gateway', type: 'trial' });
  }

  function handleCopyClick() {
    copy(terminalCommands);
  }

  const terminalCommands = `LUMEO_APP_ID=${
    applicationID ? `'${applicationID}'` : "'<WORKSPACE_ID>'"
  } LUMEO_API_KEY=${
    gatewayProvisioningToken?.content
      ? `'${gatewayProvisioningToken.content}'`
      : "'<YOUR_TOKEN>'"
  } bash <(wget -qO- https://link.lumeo.com/setup)`;

  return (
    <>
      <ExternalLink href="https://docs.lumeo.com/docs/gateway">
        Gateway setup docs
      </ExternalLink>

      {cannotAddGateway && (
        <FormMessage intent="warning" icon="warning">
          <strong>
            Your plan is limited to {currentPlan.gateway_limit} gateway
            {currentPlan.gateway_limit! > 1 ? 's' : ''}.
          </strong>
          <br />
          Please{' '}
          <Link
            className={c('link')}
            to={`/applications/${applicationID}/settings/organization/${organization.id}/plans`}
            onClick={onLinkClick}
          >
            upgrade your organization
          </Link>{' '}
          or remove an existing gateway.
        </FormMessage>
      )}

      <div className={c('content')}>
        {(canAddTrialCloudGateway || !isStarterPlan) && (
          <section className={c('section')}>
            <header>
              <Heading level="2">Add a Lumeo Cloud Gateway</Heading>
              <Text type="paragraph">
                Run video analytics and access online cameras using a
                Lumeo-managed device.
              </Text>
            </header>

            <div className={c('group')}>
              {canAddTrialCloudGateway && (
                <Box className={c('box', 'lumeo-instance')}>
                  <img
                    src={LumeoCloudGatewayImg}
                    className={c('lumeo-instance-image')}
                    alt=""
                  />
                  <Text type="paragraph">
                    Try out the platform with a free, managed gateway that
                    automatically stops after 3 hours.
                  </Text>

                  <List.Root>
                    <List.Item icon="check">
                      <Text>No credit card required</Text>
                    </List.Item>
                    <List.Item icon="check">
                      <Text>Start and stop as needed</Text>
                    </List.Item>
                  </List.Root>

                  <Button
                    className={c('button')}
                    onClick={handleStartTrialClick}
                    loading={isLoading}
                    variant="primary"
                    size="small"
                  >
                    Start trial cloud gateway
                  </Button>
                </Box>
              )}

              {!isStarterPlan && (
                <Box
                  className={c('box', 'lumeo-instance')}
                  variant={canAddTrialCloudGateway ? 'secondary' : undefined}
                >
                  {!canAddTrialCloudGateway && (
                    <img
                      src={LumeoCloudGatewayImg}
                      className={c('lumeo-instance-image')}
                      alt=""
                    />
                  )}
                  <Text type="paragraph">
                    Chat with our support to set up a Lumeo-managed Cloud
                    Gateway, tailored to your needs.
                  </Text>
                  <Button
                    className={c('button')}
                    onClick={show}
                    variant={canAddTrialCloudGateway ? 'secondary' : 'primary'}
                    size="small"
                  >
                    <Icon name="message" size="small" />
                    <span>Request Lumeo Cloud Gateway</span>
                  </Button>
                </Box>
              )}
            </div>
          </section>
        )}

        {hasAccess('gateway_tokens') && (
          <section className={c('section')}>
            <header>
              <Heading level="2">Add your own device as a gateway</Heading>
              <Text type="paragraph">
                Run video analytics and access firewalled cameras using your own
                device running on-premises or in your cloud.
              </Text>
            </header>

            <Box variant="secondary">
              <form className={c('form')} onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className={c('fields')}>
                    <Field className={c('field')} label="Name">
                      <Input
                        {...register('name', {
                          required: 'Please choose a name for your token.',
                        })}
                        type="text"
                        autoComplete="off"
                        spellCheck="false"
                      />
                    </Field>

                    <Field className={c('field')} label="Expiration">
                      <Controller
                        control={control}
                        name="expiration"
                        render={({ field: { value, onChange } }) => (
                          <ExpirationSelect value={value} onChange={onChange} />
                        )}
                      />
                    </Field>
                  </div>
                  {errors.name && (
                    <p className={c('error')}>
                      <Icon name="warning" size="small" />
                      <span>{errors.name.message}</span>
                    </p>
                  )}
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    loading={isCreatingGatewayProvisioningToken}
                  >
                    Generate token
                  </Button>
                </div>
              </form>
            </Box>

            {gatewayProvisioningToken &&
              gatewayProvisioningToken.content &&
              applicationID && (
                <TokenSuccessMessage
                  title="New gateway provisioning token generated."
                  token={gatewayProvisioningToken.content}
                >
                  <Text type="paragraph">
                    Set up a Lumeo-ready Gateway by providing this token and the
                    workspace ID{' '}
                    <CopyToClipboard
                      className={c('app-id')}
                      value={applicationID}
                    />{' '}
                    in the Gateway's web interface.
                  </Text>

                  {gatewayProvisioningToken.expires_at && (
                    <Text>
                      Token expires{' '}
                      <strong>
                        {DateTime.fromISO(
                          gatewayProvisioningToken.expires_at
                        ).toLocaleString(DateTime.DATETIME_MED)}
                      </strong>
                    </Text>
                  )}
                </TokenSuccessMessage>
              )}

            <Box className={c('box')}>
              <Text>Install Lumeo using the following command:</Text>

              <div className={c('commands')}>
                <TerminalCommands
                  commands={[terminalCommands]}
                  showCopyToClipboardButton={false}
                />
                <Tooltip
                  side="right"
                  content="Copied to clipboard!"
                  open={isCopied}
                >
                  <Button
                    className={c('button')}
                    onClick={handleCopyClick}
                    variant="secondary"
                    size="small"
                  >
                    <Icon name="clipboard" />
                    <span>Copy to clipboard</span>
                  </Button>
                </Tooltip>
              </div>
              <div className={c('bring-your-own-devices')}>
                <Heading level="4">Supported devices</Heading>
                <List.Root>
                  <List.Item icon="check">
                    <Text>NVIDIA Jetson (Jetpack 5)</Text>
                  </List.Item>
                  <List.Item icon="check">
                    <Text>
                      x86 Server with NVIDIA DGPU (Ubuntu 18 or higher)
                    </Text>
                  </List.Item>
                </List.Root>
              </div>
            </Box>
          </section>
        )}
      </div>
    </>
  );
}
