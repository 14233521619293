import { SingleValue } from 'react-select';

import Stream, { StreamType } from 'types/stream';
import { FilterInput } from 'graphql/types/filter';

import { useStreams } from 'streams/hooks/useStreams';

import { StreamSelect } from 'streams/components/StreamSelect/StreamSelect';

import { Placeholder } from './SuggestionInput';
import { filterIds } from '../service';
import { useMetricFiltersState } from '../context';

export type StreamsFilterInputProps = Pick<FilterInput, 'id' | 'value'>;

export function StreamFilterInput({ id, value }: StreamsFilterInputProps) {
  const { filters, updateFilter } = useMetricFiltersState();

  const sourceIds = filterIds('source_id', filters);

  const { data: streams, isInitialLoading } = useStreams(
    ['metric-filter-streams'],
    { stream_ids: sourceIds },
    { enabled: Boolean(sourceIds.length), keepPreviousData: true }
  );

  if (!streams && isInitialLoading) {
    return Placeholder;
  }

  function handleStreamChange(stream: SingleValue<Stream>) {
    updateFilter(id, 'value', stream?.id);
  }

  return (
    <StreamSelect
      queryFilters={{ stream_types: [StreamType.FILE, StreamType.RTSP] }}
      defaultValue={streams?.data.find(({ id }) => id === value)}
      onChange={handleStreamChange}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: 'auto',
        }),
      }}
    />
  );
}
