import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  Legend,
  LinearScale,
  Tooltip,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import { TimeRangeInput } from '@propeldata/ui-kit';

import { FilterInput } from 'graphql/types/filter';
import { CustomTimeSeriesQueryVariables } from 'graphql/queries/timeseries';

import { useDashboardWidgetParams } from 'dashboards/hooks/useDashboardWidgetParams';
import { useGroupedBarChart } from 'dashboards/hooks/useGroupedBarChart';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';
import { TimeUnitMap } from '../types/time_unit';

ChartJS.register(BarElement, Legend, LinearScale, Tooltip, TimeSeriesScale);

// Propel components use 'metric' instead of 'metricName' so we keep the same behaviour here
export type StackedBarChartQuery = Pick<
  CustomTimeSeriesQueryVariables,
  'granularity' | 'dimensions'
> & {
  metric: string;
  timeRange?: TimeRangeInput;
  filters?: FilterInput[];
};

export type StackedBarChartProps = {
  query: StackedBarChartQuery;
  variant: 'stacked' | 'grouped';
};

export function StackedBarChart({ query, variant }: StackedBarChartProps) {
  const params =
    useDashboardWidgetParams<CustomTimeSeriesQueryVariables>(query);
  const { timeZone, granularity } = params;

  const { data, isLoading, error } = useGroupedBarChart(params, variant);

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (error) {
    return (
      <ChartError
        error={{
          title: 'No data',
          body: error,
        }}
      />
    );
  }

  if (!data) {
    return null;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'timeseries' as const,
        time: {
          unit: TimeUnitMap[granularity],
        },
        adapters: {
          date: {
            zone: timeZone,
          },
        },
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        // Hide legend when it's an empty graph
        display: data.datasets.some(({ data }) =>
          data.some((value) => value > 0)
        ),
        labels: { useBorderRadius: true },
      },
    },
  };

  return <Bar data={data} options={options} />;
}
