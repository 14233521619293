import * as RadixTabs from '@radix-ui/react-tabs';
import classnames from 'classnames/bind';

import { TabsList } from './TabsList';
import { TabsContent } from './TabsContent';
import { TabsButton } from './TabsButton';
import styles from './Tabs.module.scss';

const c = classnames.bind(styles);

export function Tabs({ className, children, ...props }: RadixTabs.TabsProps) {
  return (
    <RadixTabs.Root {...props} className={c(className, 'tabs')}>
      {children}
    </RadixTabs.Root>
  );
}

export { TabsList, TabsButton, TabsContent };
