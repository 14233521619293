import classNames from 'classnames/bind';
import { Slider, SliderProps } from '@mantine/core';

import styles from './FilesSearchConfidenceSlider.module.scss';

const c = classNames.bind(styles);

export function FilesSearchConfidenceSlider({
  className,
  ...props
}: SliderProps) {
  return (
    <div className={c('confidence', className)}>
      <label htmlFor="search_confidence">Quantity</label>
      <Slider
        {...props}
        id="search_confidence"
        className={c('confidence-input')}
        classNames={{
          bar: c('confidence-input-bar'),
          thumb: c('confidence-input-thumb'),
        }}
        min={0}
        max={1}
        step={0.1}
        defaultValue={0.5}
        thumbSize={20}
        size="sm"
        label={null}
      />
      <label htmlFor="search_confidence">Relevance</label>
    </div>
  );
}
