import classnames from 'classnames/bind';
import { Routes, Route, Navigate } from 'react-router-dom';

import { DesignRoute } from 'application/types/routes';

import { AIModels } from 'analytics-library/views/AIModels/AIModels';
import { CreateModelView } from 'analytics-library/views/CreateModelView/CreateModelView';
import { Marketplace } from 'analytics-library/views/Marketplace/Marketplace';
import { ModelDetailContainer } from 'analytics-library/views/ModelDetail/ModelDetailContainer';
import { PipelineDetail } from 'pipelines/views/PipelineDetail/PipelineDetail';
import { PipelineOverview } from 'pipelines/views/PipelineOverview/PipelineOverview';
import { Solutions } from 'analytics-library/views/Solutions/Solutions';
import {
  Step1,
  Step2,
  Step3,
} from 'analytics-library/components/MultiStepForm';

import { DesignSidebar } from './Sidebar';
import styles from './Design.module.scss';

const c = classnames.bind(styles);

export function Design() {
  return (
    <div className={c('design')}>
      <DesignSidebar />

      <Routes>
        <Route
          path="*"
          element={<Navigate to={DesignRoute.PIPELINES} replace />}
        />

        <Route
          path={`${DesignRoute.PIPELINES}/*`}
          element={<PipelineOverview />}
        />
        <Route
          path={`${DesignRoute.PIPELINES}/:pipelineID`}
          element={<PipelineDetail />}
        />

        <Route path={`${DesignRoute.MODELS}/*`} element={<AIModels />} />
        <Route
          path={`${DesignRoute.MODELS}/:modelID`}
          element={<ModelDetailContainer />}
        />
        <Route
          path={`${DesignRoute.MODELS}/new/*`}
          element={<CreateModelView />}
        >
          <Route index element={<Step1 />} />
          <Route path="parameters" element={<Step2 />} />
          <Route path="inference-config" element={<Step3 />} />
        </Route>

        <Route path={DesignRoute.SOLUTIONS} element={<Solutions />} />
        <Route path={DesignRoute.MARKETPLACE} element={<Marketplace />} />
      </Routes>
    </div>
  );
}
