import classNames from 'classnames/bind';
import { UseComboboxReturnValue } from 'downshift';

import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { QuerySearchProps, SearchableEntity } from './QuerySearch';
import styles from './QuerySearchInput.module.scss';
import { UseQueryResult } from '@tanstack/react-query';
import { PaginatedAPIResponse } from 'types/api';
import APIError from 'types/api_error';

const c = classNames.bind(styles);

export type QuerySearchSuggestionsProps<T extends SearchableEntity> = Pick<
  UseComboboxReturnValue<T | null>,
  'inputValue' | 'getItemProps' | 'highlightedIndex' | 'closeMenu'
> &
  Pick<QuerySearchProps<T>, 'onApplyFilters'> & {
    items: T[];
    searchQueryResult: UseQueryResult<PaginatedAPIResponse<T>, APIError>;
    itemToString: (item: T | null) => string;
  };

export function QuerySearchSuggestions<T extends SearchableEntity>({
  items,
  inputValue,
  getItemProps,
  highlightedIndex,
  itemToString,
  onApplyFilters,
  closeMenu,
  searchQueryResult: { data, isLoading, error },
}: QuerySearchSuggestionsProps<T>) {
  if (error) {
    return (
      <div className={c('error', 'theme danger')}>
        <Icon name="error" size="large" />
        <Text>{error.message}</Text>
      </div>
    );
  }
  function handleAllResultsClick() {
    onApplyFilters?.();
    closeMenu();
  }

  if (!data || isLoading) {
    return <Loader size="small" text="Searching..." />;
  }

  if (data.total_elements === 0) {
    return <Text className={c('info')}>No results found</Text>;
  }

  return (
    <>
      <Text className={c('info')}>
        Showing{' '}
        <strong>
          {data.page_elements} of {data.total_elements}
        </strong>{' '}
        results
      </Text>

      <Separator />

      <ul>
        {items.map((item, index) => (
          <li
            {...getItemProps({ item, index })}
            className={c('suggestion-item', {
              active: highlightedIndex === index,
            })}
            key={index}
          >
            {itemToString(item)}
          </li>
        ))}

        {data.total_elements > data.page_elements ? (
          <>
            <Separator />
            <li
              {...getItemProps({
                item: null,
                index: items.length,
                onClick: handleAllResultsClick,
              })}
              className={c('suggestion-item', {
                active: highlightedIndex === items.length,
              })}
            >
              <Icon name="search" />
              <span>All search results for "{inputValue}"</span>
            </li>
          </>
        ) : null}
      </ul>
    </>
  );
}
