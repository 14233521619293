import classnames from 'classnames/bind';
import JsonView from '@uiw/react-json-view';

import * as Dialog from 'components/Dialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import styles from './LogInspector.module.scss';

const c = classnames.bind(styles);

type LogInspectorProps = Pick<Dialog.RootProps, 'open' | 'onOpenChange'> & {
  log?: string;
};

export function LogInspector({ log, open, onOpenChange }: LogInspectorProps) {
  if (!log) {
    return null;
  }
  let json: object | undefined;

  try {
    json = JSON.parse(log);
  } catch (error) {}

  const isJSON = json && typeof json === 'object';

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Title className={c('title')}>
        <Heading level="2" asChild>
          <span>Log</span>
        </Heading>
        <CopyToClipboardButton
          message="Copied log to clipboard!"
          text={log}
          inline
        />
      </Dialog.Title>

      <div className={c('content', { 'plain-text': !isJSON })}>
        {json && isJSON ? (
          <JsonView
            className={c('json-view')}
            collapsed={false}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            value={json}
          >
            {/* Workaround for null and NaN values not being displayed in v2.0.0-alpha.27 */}
            {/* Custom rendering can likely be removed in future versions of react-json-view */}
            <JsonView.Null
              render={({ children, ...props }, { value }) => (
                <span {...props}>{String(value)}</span>
              )}
            />
            <JsonView.Nan
              render={({ children, ...props }, { value }) => (
                <span {...props}>{String(value)}</span>
              )}
            />
          </JsonView>
        ) : (
          <Text type="paragraph">{log}</Text>
        )}
      </div>
    </Dialog.Root>
  );
}
