import { FormMessage } from 'components/FormMessage/FormMessage';

import Pipeline from 'types/pipeline';

export type DeploymentFormNoSourceNodeWarningProps = {
  pipeline: Pipeline | undefined;
};

export function DeploymentFormNoSourceNodeWarning({
  pipeline,
}: DeploymentFormNoSourceNodeWarningProps) {
  if (!pipeline || pipeline.hasSourceNode) {
    return null;
  }

  return (
    <FormMessage icon="warning" intent="danger">
      This pipeline does not contain a source node.
    </FormMessage>
  );
}
