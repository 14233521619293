import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import Dashboard from 'types/dashboard';
import { MonitorRoute, NavRoute } from 'application/types/routes';

import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreateDashboard } from 'dashboards/hooks/useCreateDashboard';
import { useDashboardFilterParams } from 'dashboards/hooks/useDashboardFilterParams';
import { useDeleteDashboard } from 'dashboards/hooks/useDeleteDashboard';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { IconButton } from 'components/IconButton/IconButton';
import { RenameDashboardDialog } from 'dashboards/components/RenameDashboardDialog/RenameDashboardDialog';
import { SidebarLink } from 'components/Sidebar/SidebarLink';

import styles from './Monitor.module.scss';

export const c = classNames.bind(styles);

export type DashboardLinkProps = {
  dashboard: Dashboard;
};

export function DashboardLink({ dashboard }: DashboardLinkProps) {
  const redirect = useAppRedirect();
  const { pathname } = useLocation();

  const timeRangeParams = useTimeRangeParams();
  const filterParams = useDashboardFilterParams();

  const search = new URLSearchParams({
    ...timeRangeParams,
    ...filterParams,
  }).toString();

  const [requestDeleteDashboard] = useDeleteDashboard({
    onMutate(dashboardID) {
      if (pathname.includes(dashboardID)) {
        // Empty timeout to properly queue this until after query data has
        // been updated to prevent redirecting user to a 404 screen
        window.setTimeout(() => redirect('/monitor'));
      }
    },
  });
  const [isRenaming, toggleIsRenaming] = useToggle();

  function handleDeleteClick() {
    requestDeleteDashboard(dashboard.id);
  }

  const { mutate: createDashboard } = useCreateDashboard({
    onSuccess(dashboard) {
      redirect({
        pathname: `/${NavRoute.MONITOR}/${MonitorRoute.DASHBOARDS}/${dashboard.id}`,
        search,
      });
    },
  });

  function handleDuplicateClick() {
    const duplicatedDashboard = dashboard.duplicate();
    createDashboard(duplicatedDashboard);
  }

  return (
    <>
      <SidebarLink
        to={{
          pathname: `/${NavRoute.MONITOR}/${MonitorRoute.DASHBOARDS}/${dashboard.id}`,
          search,
        }}
        action={
          <ContextMenu
            trigger={
              <IconButton
                className={c('dashboard-link-edit')}
                size="small"
                icon="more-vertical"
                label={`More options for ${dashboard.name}`}
              />
            }
          >
            <ContextMenuAction icon="settings" onClick={toggleIsRenaming}>
              Rename
            </ContextMenuAction>
            <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
              Duplicate
            </ContextMenuAction>
            <ContextMenuAction
              intent="danger"
              icon="delete"
              onClick={handleDeleteClick}
            >
              Delete
            </ContextMenuAction>
          </ContextMenu>
        }
      >
        <span>{dashboard.name}</span>
      </SidebarLink>

      {isRenaming && (
        <RenameDashboardDialog
          dashboard={dashboard}
          open={isRenaming}
          onOpenChange={toggleIsRenaming}
        />
      )}
    </>
  );
}

export function DashboardLinkSkeleton() {
  return (
    <div className={c('dashboard-link')}>
      <span className="skeleton-text" aria-hidden="true">
        Dashboard
      </span>
    </div>
  );
}
