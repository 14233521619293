import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Options } from 'react-select';

import { AccessTokenType } from 'types/token';

import {
  useAccessTokens,
  useCreateAccessToken,
} from 'hooks/api/useAccessTokens';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import {
  Expiration,
  EXPIRATION_OPTIONS,
  ExpirationOption,
  ExpirationSelect,
} from 'components/ExpirationSelect/ExpirationSelect';
import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { Text } from 'components/Text/Text';

import { TokenSuccessMessage } from '../TokenSuccessMessage/TokenSuccessMessage';
import styles from './TokenForm.module.scss';

const c = classnames.bind(styles);

type AccessTokenTypeOption = {
  value: AccessTokenType;
  label: string;
};

const ACCESS_TOKEN_TYPE_OPTIONS: Options<AccessTokenTypeOption> = [
  { value: 'application', label: 'Application' },
  { value: 'gateway_provisioning', label: 'Gateway provisioning' },
  { value: 'mintable', label: 'Universal bridge' },
];

type AccessTokenFieldValues = {
  name: string;
  type: AccessTokenTypeOption;
  expiration: ExpirationOption;
};

type TokenFormProps = {
  title: string;
  types: AccessTokenType[];
  defaultExpiration?: Expiration;
};

export function TokenForm({ title, types, defaultExpiration }: TokenFormProps) {
  const { data: accessTokens } = useAccessTokens({ keepPreviousData: false });

  const options = ACCESS_TOKEN_TYPE_OPTIONS.filter(({ value }) =>
    types.includes(value)
  );

  const [type = { value: 'application', label: 'Application' }] = options;
  const expiration = EXPIRATION_OPTIONS[defaultExpiration ?? '30 days'];

  const {
    control,
    resetField,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AccessTokenFieldValues>({ defaultValues: { type, expiration } });

  const {
    data: newAccessToken,
    mutate: createAccessToken,
    isLoading: isCreatingAccessToken,
  } = useCreateAccessToken({
    onSuccess() {
      resetField('name');
    },
  });

  function onSubmit({
    name,
    type: { value: type },
    expiration: { value },
  }: AccessTokenFieldValues) {
    createAccessToken({ name, type, expires_at: value() });
  }

  // Show token message as long as it is not deleted
  const isDeletedToken =
    newAccessToken && accessTokens
      ? accessTokens.every(({ id }) => id !== newAccessToken.id)
      : true;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={c('box')} variant="secondary">
          <Heading level="3">{title}</Heading>

          <div>
            <div className={c('fields')}>
              <Field className={c('field')} label="Name">
                <Input
                  {...register('name', {
                    required: 'Please choose a name for your token.',
                  })}
                  type="text"
                  autoComplete="off"
                  spellCheck="false"
                />
              </Field>

              {types.length > 1 && (
                <Field className={c('field')} label="Type">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        options={options}
                        onChange={onChange}
                      />
                    )}
                  />
                </Field>
              )}

              <Field className={c('field')} label="Expiration">
                <Controller
                  control={control}
                  name="expiration"
                  render={({ field: { value, onChange } }) => (
                    <ExpirationSelect value={value} onChange={onChange} />
                  )}
                />
              </Field>
            </div>
            {errors.name && (
              <p className={c('error')}>
                <Icon name="warning" size="small" />
                <span>{errors.name.message}</span>
              </p>
            )}
          </div>

          <div>
            <Button
              className={c('button')}
              type="submit"
              variant="primary"
              loading={isCreatingAccessToken}
            >
              Generate token
            </Button>
          </div>
        </Box>
      </form>

      {!isDeletedToken && newAccessToken && newAccessToken.content && (
        <TokenSuccessMessage
          title="New access token generated."
          token={newAccessToken.content}
        >
          <Text type="paragraph">
            Make sure to copy your new access token, as you will not be able to
            see it again.
          </Text>

          {newAccessToken.expires_at && (
            <Text>
              Token expires{' '}
              <strong>
                {DateTime.fromISO(newAccessToken.expires_at).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </strong>
            </Text>
          )}
        </TokenSuccessMessage>
      )}
    </>
  );
}
