import classnames from 'classnames/bind';

import { CellProps } from 'react-table';

import Account from 'types/account';

import { useAccount } from 'hooks/api/useAccount';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useDeleteOrganizationMember } from 'settings/hooks/useDeleteOrganizationMember';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Text } from 'components/Text/Text';

import styles from '../Table.module.scss';

const c = classnames.bind(styles);

export function MemberCell({ row }: CellProps<Account>) {
  const { data: account } = useAccount();
  const { data: organization, isLoading: isLoading } = useCurrentOrganization();
  const [requestDeleteMember] = useDeleteOrganizationMember();

  const isDisabled = !organization || isLoading;

  const hasOwnerRole =
    account && organization
      ? account.roles.some(
          ({ name, organization_id }) =>
            organization_id &&
            organization_id === organization.id &&
            name === 'owner'
        )
      : false;

  // Members with owner or billing role can only be removed by members with owner role
  const canDelete = organization
    ? !row.original.roles.some(
        ({ name, organization_id }) =>
          organization_id &&
          organization_id === organization.id &&
          (name === 'owner' || name === 'billing')
      ) || hasOwnerRole
    : false;

  const isTrueOwner = Boolean(
    organization && organization.account_id === row.original.id
  );

  function handleDeleteMember() {
    requestDeleteMember(row.original.id);
  }

  return (
    <div className={c('member')}>
      <div>
        <Heading level="4">{row.original.email}</Heading>
        {row.original.email !== row.original.name && (
          <Text>{row.original.name}</Text>
        )}
      </div>

      <ContextMenu
        trigger={
          <IconButton
            icon="more-vertical"
            label="Reveal more actions"
            variant="ghost"
            size="small"
          />
        }
      >
        <ContextMenuAction
          onClick={handleDeleteMember}
          icon="delete"
          intent="danger"
          disabled={isDisabled || isTrueOwner || !canDelete}
        >
          Remove from organization
        </ContextMenuAction>
      </ContextMenu>
    </div>
  );
}
