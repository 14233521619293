import classnames from 'classnames/bind';
import { DateTime } from 'luxon';
import { FilterOperator, Sort } from '@propeldata/ui-kit';
import { useParams } from 'react-router-dom';

import {
  isPropelGatewayMetric,
  PROPEL_GATEWAY_METRICS_LATEST,
  PropelGatewayMetric,
} from 'gateways/services/propel_gateway_metrics';

import { useMetricReport } from 'graphql/hooks/useMetricReport';

import { Heading } from 'components/Heading/Heading';
import { Meter } from 'components/Meter';
import { Text } from 'components/Text/Text';

import { GatewayParams } from './GatewayDetail';
import styles from './Utilization.module.scss';

const c = classnames.bind(styles);

export function GatewayDetailHardwareUtilization() {
  const { gatewayID } = useParams<GatewayParams>();

  const { data } = useMetricReport({
    metrics: PROPEL_GATEWAY_METRICS_LATEST,
    dimensions: [{ columnName: 'timestamp', sort: Sort.Desc }],
    filters: [
      {
        column: 'gateway_id',
        operator: FilterOperator.Equals,
        value: gatewayID,
      },
    ],
    orderByColumn: 1,
    first: 1,
  });

  if (!data) {
    return null;
  }

  const {
    metricReport: { headers, rows },
  } = data;

  if (!rows.length) {
    return null;
  }

  const [row] = rows;

  const values = Object.fromEntries(
    headers.map((header, index) => [
      header,
      isPropelGatewayMetric(header)
        ? String(Math.round(Number(row[index])))
        : row[index],
    ])
  ) as Record<PropelGatewayMetric | 'timestamp', string>;

  const high = 89;
  const max = 100;

  return (
    <div className={c('wrap')}>
      <Text className={c('last-updated')} inline>
        Last updated:{' '}
        {DateTime.fromISO(values['timestamp']).toLocaleString(
          DateTime.DATETIME_MED
        )}
      </Text>
      <div className={c('processors')}>
        <section>
          <Heading className={c('heading')} level="3">
            CPU
          </Heading>
          <div className={c('values')}>
            <label className={c('metric')}>
              <Heading level="4" className={c('percent')}>
                {values['Gateway CPU Utilization Latest']}%
              </Heading>
              <Text className={'text'}>Utilization</Text>
              <Meter
                className={c('meter')}
                max={max}
                high={high}
                value={values['Gateway CPU Utilization Latest']}
              />
            </label>
            <label className={c('metric')}>
              <Heading level="4" className={c('percent')}>
                {values['Gateway CPU Memory Usage Percent Latest']}%
              </Heading>
              <Text className={'text'}>Memory</Text>
              <Meter
                className={c('meter')}
                max={max}
                high={high}
                value={values['Gateway CPU Memory Usage Percent Latest']}
              />
            </label>
          </div>
        </section>

        <section>
          <Heading className={c('heading')} level="3">
            GPU
          </Heading>
          <div className={c('values')}>
            <label className={c('metric')}>
              <Heading level="4" className={c('percent')}>
                {values['Gateway GPU Utilization Latest']}%
              </Heading>
              <Text className={'text'}>Utilization</Text>
              <Meter
                className={c('meter')}
                max={max}
                high={high}
                value={values['Gateway GPU Utilization Latest']}
              />
            </label>
            <label className={c('metric')}>
              <Heading level="4" className={c('percent')}>
                {values['Gateway GPU Memory Usage Percent Latest']}%
              </Heading>
              <Text className={'text'}>Memory</Text>
              <Meter
                className={c('meter')}
                max={max}
                high={high}
                value={values['Gateway GPU Memory Usage Percent Latest']}
              />
            </label>
          </div>
        </section>
      </div>

      <div className={c('storage')}>
        <section>
          <Heading className={c('heading')} level="3">
            Storage
          </Heading>
          <label className={c('metric')}>
            <Heading level="4" className={c('percent')}>
              {values['Gateway Disk Usage Percent Latest']}%
            </Heading>
            <Text className={'text'}>Utilization</Text>
            <Meter
              className={c('storage-meter')}
              max={max}
              high={high}
              size="large"
              value={values['Gateway Disk Usage Percent Latest']}
            />
          </label>
        </section>
      </div>
    </div>
  );
}
