import AIModel from 'types/ai_model';

import { useMarketplaceModels } from 'hooks/api/useModels';

import * as PageLayout from 'components/PageLayout';
import { AdvancedSearch } from 'components/AdvancedSearch/AdvancedSearch';
import { ModelGrid } from 'analytics-library/components/ModelGrid/ModelGrid';

const EMPTY_MODELS: AIModel[] = [];

// Ready-to-use AI Models
export function Marketplace() {
  const { data: marketplaceModels, isLoading } = useMarketplaceModels();

  return (
    <PageLayout.Root>
      <AdvancedSearch
        data={marketplaceModels || EMPTY_MODELS}
        isLoading={isLoading}
        searchLabel="Search models"
        entity="models"
      >
        <ModelGrid isMarketplace />
      </AdvancedSearch>
    </PageLayout.Root>
  );
}
