import { createRoot } from 'react-dom/client';
import { TooltipProvider } from '@radix-ui/react-tooltip';

import {
  ErrorNotification,
  ErrorNotificationProps,
} from 'components/Notifications/ErrorNotification';
import {
  Notification,
  NotificationProps,
} from 'components/Notifications/Notification';

const containerElement = document.getElementById('notification-center');

/**
 * Creates a self-destructing notification based on the Notification component.
 * Notifications created with this function will persist through navigation
 * and do not depend on conditional rendering.
 */
export function addNotification({
  title,
  onClose,
  ...props
}: NotificationProps) {
  const id = title;
  const wrapper = document.createElement('div');
  wrapper.id = id;
  containerElement?.appendChild(wrapper);

  createRoot(wrapper).render(
    <TooltipProvider>
      <Notification
        {...props}
        title={title}
        onClose={() => {
          wrapper.remove();
          onClose?.();
        }}
      />
    </TooltipProvider>
  );
}

export function addErrorNotification({
  title,
  onClose,
  ...props
}: ErrorNotificationProps) {
  const id = title;
  const wrapper = document.createElement('div');
  wrapper.id = id;
  containerElement?.appendChild(wrapper);

  createRoot(wrapper).render(
    <TooltipProvider>
      <ErrorNotification
        {...props}
        title={title}
        onClose={() => {
          wrapper.remove();
          onClose?.();
        }}
      />
    </TooltipProvider>
  );
}
