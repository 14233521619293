import classnames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';

import Camera from 'types/camera';

import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { LightSwitch } from 'components/LightSwitch/LightSwitch';
import { Text } from 'components/Text/Text';

import styles from './CameraDetailSettings.module.scss';

const c = classnames.bind(styles);

export type CameraDetailNetworkSettingsProps = {
  camera: Camera;
};

type NetworkSettingsFieldValues = Pick<Camera, 'rtsp_config'>;

export function CameraDetailNetworkSettings({
  camera,
}: CameraDetailNetworkSettingsProps) {
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<NetworkSettingsFieldValues>({
    defaultValues: camera,
  });

  const {
    mutate: updateCamera,
    isLoading,
    isSuccess,
    error,
  } = useUpdateCamera({
    onSuccess({ rtsp_config }) {
      reset({ rtsp_config });
    },
  });

  function onSubmit({ rtsp_config }: NetworkSettingsFieldValues) {
    const updatedCamera = camera.copy();
    updatedCamera.rtsp_config = rtsp_config;
    updateCamera(updatedCamera);
  }

  return (
    <section>
      <Heading level="3">Network settings</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Always use TCP">
          <div className={c('lightswitch')}>
            <Controller
              name="rtsp_config"
              control={control}
              render={({ field: { onChange, value } }) => (
                <LightSwitch
                  id="force_tcp"
                  onChange={(event) => {
                    if (event.target.checked) {
                      onChange({ protocols: ['tcp'] });
                    } else {
                      onChange(null);
                    }
                  }}
                  checked={Boolean(value)}
                />
              )}
            />
            <Text type="paragraph" asChild>
              <label htmlFor="force_tcp">
                Enable to use TCP for improved reliability in congested or other
                network situations.
              </label>
            </Text>
          </div>
        </Field>

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={isLoading}
          >
            Save network settings
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && !isDirty && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>
    </section>
  );
}
