import classnames from 'classnames/bind';

import { useAPI } from 'hooks/api/useAPI';

import { Button } from 'components/Button/Button';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Text } from 'components/Text/Text';

import { ReactComponent as EmptyIllustration } from 'deployments/images/empty-deployment-dialog.svg';
import styles from './DeployabilityModal.module.scss';

const c = classnames.bind(styles);

export type CannotDeployInfoProps = {
  hasGateways: boolean;
  hasInputSources: boolean;
  hasPipelines: boolean;
};

export function CannotDeployInfo({
  hasGateways,
  hasInputSources,
  hasPipelines,
}: CannotDeployInfoProps) {
  const { applicationID } = useAPI();

  return (
    <div className={c('dialog')}>
      <EmptyView image={EmptyIllustration} title="Deploy pipeline">
        <Text type="paragraph">
          To deploy a pipeline, please make sure you have completed the
          following steps:
        </Text>

        <ul className={c('walkthrough')}>
          <li>
            <StatusIndicator
              status={hasPipelines ? 'success' : 'progress'}
              size="small"
              className={c('walkthrough-icon')}
            />
            <Button
              variant={hasPipelines ? 'none' : 'link'}
              to={`/applications/${applicationID}/design/pipelines`}
            >
              Create a pipeline
            </Button>
          </li>

          <li>
            <StatusIndicator
              status={hasGateways ? 'success' : 'progress'}
              size="small"
              className={c('walkthrough-icon')}
            />
            <Button
              variant={hasGateways ? 'none' : 'link'}
              to={`/applications/${applicationID}/deploy/gateways`}
            >
              Link a gateway
            </Button>
          </li>

          <li>
            <StatusIndicator
              status={hasInputSources ? 'success' : 'progress'}
              size="small"
              className={c('walkthrough-icon')}
            />
            <Button
              variant={hasInputSources ? 'none' : 'link'}
              to={`/applications/${applicationID}/deploy/cameras`}
            >
              Add a camera
            </Button>
            ,{' '}
            <Button
              variant={hasInputSources ? 'none' : 'link'}
              to={`/applications/${applicationID}/deploy/files`}
            >
              file
            </Button>
            ,<span className={c('walkthrough-text')}>or</span>
            <Button
              variant={hasInputSources ? 'none' : 'link'}
              to={`/applications/${applicationID}/deploy/streams`}
            >
              stream
            </Button>
          </li>
        </ul>
      </EmptyView>
    </div>
  );
}
