import { useAppRedirect } from 'hooks/useAppRedirect';

import { Drawer, DrawerProps } from 'components/Drawer/Drawer';

import { AddGateway } from './AddGateway';

export type AddGatewayModalProps = Pick<DrawerProps, 'open' | 'onClose'>;

export function AddGatewayModal({ open, onClose }: AddGatewayModalProps) {
  const redirect = useAppRedirect();

  function handleAddGatewaySuccess() {
    redirect('/deploy/gateways');
    onClose?.();
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <AddGateway onSuccess={handleAddGatewaySuccess} onLinkClick={onClose} />
    </Drawer>
  );
}
