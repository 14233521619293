import { useLocation } from 'react-router-dom';

import Gateway from 'types/gateway';

type DashboardFilterParams = {
  gateway_id?: Gateway['id'];
};

export function useDashboardFilterParams(): DashboardFilterParams {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const gatewayId = params.get('gateway_id');

  if (gatewayId) {
    return {
      gateway_id: gatewayId,
    };
  }

  return {};
}
