import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import Camera from 'types/camera';

import { useUpdateCamera } from 'cameras/hooks/useUpdateCamera';
import { useTakeSnapshot } from 'hooks/api/useTakeSnapshot';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormErrorMessage';
import { Heading } from 'components/Heading/Heading';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Input } from 'components/Input/Input';

import styles from './CameraDetailSettings.module.scss';

const c = classnames.bind(styles);

type CredentialSettingsFieldValues = {
  username: string;
  password: string;
};

type CredentialSettingsProps = {
  camera: Camera;
};

export function CredentialSettings({ camera }: CredentialSettingsProps) {
  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<CredentialSettingsFieldValues>({
    defaultValues: { username: camera.username, password: camera.password },
  });

  const {
    mutateAsync: updateCamera,
    isLoading,
    isSuccess,
    error,
  } = useUpdateCamera({
    onSuccess({ username, password }) {
      reset({ username, password });
      takeSnapshot();
    },
  });

  const { mutate: takeSnapshot } = useTakeSnapshot(camera);

  function onSubmit({ username, password }: CredentialSettingsFieldValues) {
    const updatedCamera = camera.copy();

    if (username) {
      updatedCamera.username = username;
    }

    if (password) {
      updatedCamera.password = password;
    }

    updateCamera(updatedCamera);
  }

  return (
    <section>
      <Heading level="3">Credentials</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={c('credentials')}>
          <Field label="Username">
            <Input {...register('username')} />
          </Field>

          <Field label="Password">
            <Input type="password" {...register('password')} />
          </Field>
        </div>

        <FormErrorMessage error={error} />

        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={isLoading}
          >
            Save credentials
          </Button>

          {isDirty && <InlineNotification>Unsaved changes</InlineNotification>}

          {isSuccess && !isDirty && (
            <InlineNotification intent="success">Saved!</InlineNotification>
          )}
        </ButtonGroup>
      </form>
    </section>
  );
}
