import React from 'react';
import classNames from 'classnames/bind';

import { MultiStreamWidget } from 'types/dashboard_widget';
import { StreamType } from 'types/stream';

import { UseStreamsFilterParams, useStreams } from 'streams/hooks/useStreams';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { QuerySearch } from 'components/QuerySearch/QuerySearch';
import { ReactComponent as EmptyIllustration } from 'streams/images/empty-streams-illustration.svg';
import { VideoSourceSnapshot } from 'components/VideoSourceSnapshot/VideoSourceSnapshot';
import { WebRTCPlayer } from 'components/WebRTCPlayer/WebRTCPlayer';

import { DashboardWidgetProps } from '../../types/widget_props';
import styles from './MultiStream.module.scss';

const c = classNames.bind(styles);

export function DashboardMultiStream({
  id,
  options,
  name,
  autoPlay,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<MultiStreamWidget>) {
  const { editWidget } = useWidgetModal();

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  const [searchFilters, setSearchFilters] =
    React.useState<UseStreamsFilterParams>({});

  const [queryFilters, setQueryFilters] =
    React.useState<UseStreamsFilterParams>({});

  const searchQueryResult = useStreams(
    ['dashboard-multistream-search-suggestions'],
    {
      ...options,
      ...searchFilters,
      stream_types: [StreamType.WEBRTC, StreamType.RTSP],
      limit: 20,
    },
    { enabled: Object.keys(searchFilters).length > 0, keepPreviousData: false }
  );

  const { data: streams } = useStreams(['streams', 'multistream-widget'], {
    ...options,
    ...queryFilters,
    limit: 100,
    stream_types: [StreamType.WEBRTC, StreamType.RTSP],
  });

  useMqttStreamsState(streams?.data ? streams.data.map(({ id }) => id) : []);

  // Filter streams to only show one stream per camera
  const filteredStreams = streams?.data?.filter((stream, _, array) => {
    if (!stream.camera_id) {
      return true;
    }

    const cameraStreams = array.filter(
      (selectedStream) => selectedStream.camera_id === stream.camera_id
    );

    const h264Streams = cameraStreams.filter(
      (selectedStream) => selectedStream.isH264Stream
    );

    if (h264Streams.length > 0) {
      return stream.id === h264Streams[0].id;
    }

    return stream.id === cameraStreams[0].id;
  });

  const hasMatchingStreams = Boolean(filteredStreams?.length);

  const handleSearchInput = React.useCallback((value?: string) => {
    if (!value) {
      setSearchFilters({});
      setQueryFilters({});
      return;
    }

    setSearchFilters((previousFilters) => ({
      ...previousFilters,
      stream_names: [value],
    }));
  }, []);

  function handleApplyFilters() {
    setQueryFilters(searchFilters);
  }

  return (
    <div className={c('wrap')}>
      <div className={c('header')}>
        <Heading level={3}>
          <span>{name}</span>
        </Heading>
        {!autoPlay && (
          <QuerySearch
            className={c('search')}
            entityName="stream"
            searchLabel="Search streams..."
            searchResultField="name"
            searchQueryResult={searchQueryResult}
            onApplyFilters={handleApplyFilters}
            onValueChange={handleSearchInput}
            totalElements={filteredStreams?.length}
          />
        )}
        <ContextMenu
          trigger={
            <IconButton
              className={c('context-menu')}
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>

      {!hasMatchingStreams && (
        <EmptyView image={EmptyIllustration}>
          No streams matching the filter.
        </EmptyView>
      )}

      <div className={c('grid')}>
        {filteredStreams &&
          hasMatchingStreams &&
          filteredStreams.map((stream) => {
            if (!stream.id) return null;

            return (
              <div className={c('grid-item')} key={stream.id}>
                {autoPlay ? (
                  <WebRTCPlayer
                    className={c('stream-player')}
                    streamID={stream.id}
                    auto
                  />
                ) : (
                  <VideoSourceSnapshot
                    className={c('grid-item-preview')}
                    source={stream}
                    autoRefresh
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
