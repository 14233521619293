import Account from 'types/account';

import { useOrganizations } from 'hooks/api/useOrganizations';

import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';

import { RolesOrganizationTable } from './OrganizationTable';

export type AccountRolesProps = {
  account: Account;
};

export function AccountRoles({ account }: AccountRolesProps) {
  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    isError,
    error,
  } = useOrganizations();

  if (isLoadingOrganizations) {
    return <Loader text="Loading organizations..." />;
  }

  if (isError) {
    return (
      <Text intent="danger">
        {error?.message ?? 'An unknown error occurred. Please try again later.'}
      </Text>
    );
  }

  return (
    <div>
      {organizations?.map((organization) => (
        <RolesOrganizationTable
          organization={organization}
          account={account}
          key={organization.id}
        />
      ))}
    </div>
  );
}
