import * as PageLayout from 'components/PageLayout';

import { FilesOverviewTable } from 'files/views/FilesOverview/Table/Table';

export function FilesOverview() {
  return (
    <PageLayout.Root>
      <PageLayout.Container size="full">
        <FilesOverviewTable />
      </PageLayout.Container>
    </PageLayout.Root>
  );
}
