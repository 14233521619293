import classNames from 'classnames/bind';

import APIError from 'types/api_error';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './Box.module.scss';

const c = classNames.bind(styles);

export type BoxErrorProps = {
  error?: APIError | string | null;
};

export function BoxError({ error }: BoxErrorProps) {
  if (!error) {
    return null;
  }

  const message =
    typeof error === 'object' && 'message' in error ? error.message : error;

  return (
    <div className={c('error', 'theme danger')}>
      <Icon name="warning" size="large" />

      <Text intent="danger">
        {message ?? 'An unknown error occurred. Please try again later.'}
      </Text>
    </div>
  );
}
