import { FormMessage } from 'components/FormMessage/FormMessage';

import Pipeline from 'types/pipeline';

export type DeploymentFormDisconnectedNodesWarningProps = {
  pipeline: Pipeline | undefined;
};

export function DeploymentFormDisconnectedNodesWarning({
  pipeline,
}: DeploymentFormDisconnectedNodesWarningProps) {
  if (!pipeline || pipeline.disconnectedNodes.length === 0) {
    return null;
  }

  return (
    <FormMessage icon="warning" intent="warning">
      This pipeline contains disconnected nodes and may not work as expected:
      <br />
      <strong>
        {pipeline.disconnectedNodes.map((node: any) => node.id).join(', ')}
      </strong>
    </FormMessage>
  );
}
