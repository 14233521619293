import Rete, { Connection, Input, Node, NodeEditor, Output } from 'rete';

import { getComponentByName } from '../nodes';
import Component from '../nodes/component';

export type ReteNodeDynamicSocketsData = {
  input?: number;
  output?: number;
};

function getDynamicSocketType(Cls: typeof Component) {
  if (!Cls.dynamicSocketKeys) {
    return;
  }

  return Object.keys(Cls.dynamicSocketKeys)[0];
}

function install(editor: NodeEditor) {
  editor.on(['nodecreate', 'nodeupdated'], (node) => {
    const Cls = getComponentByName(node.name) as typeof Component;

    if (!Cls || (!Cls.outputParams && !Cls.inputParams)) {
      return;
    }

    const type = getDynamicSocketType(Cls);
    if (type) {
      handleSockets(node, Cls, type);
    }
  });

  async function handleSockets(
    node: Node,
    Cls: typeof Component,
    type: string
  ) {
    if (!Cls.dynamicSocketKeys) {
      return;
    }

    const data = node.data as ReteNodeDynamicSocketsData;
    const dataAccessor = Object.values(
      Cls.dynamicSocketKeys
    )[0] as keyof typeof data;
    const accessor = `${type}s` as keyof typeof node;

    const sockets = node[accessor] as Map<string, Output | Input>;
    const newCount = Number(data[dataAccessor]);
    const delta = newCount - sockets.size;

    if (delta > 0) {
      for (let i = sockets.size + 1; i <= newCount; i++) {
        if (type === 'output' && Cls.outputParams) {
          node.addOutput(
            new Rete.Output(`${type}${i}`, `${type}${i}`, ...Cls.outputParams)
          );
        } else if (type === 'input' && Cls.inputParams) {
          node.addInput(
            new Rete.Input(`${type}${i}`, `${type}${i}`, ...Cls.inputParams)
          );
        }
      }
    } else if (delta < 0) {
      for (let i = sockets.size; i > newCount; i--) {
        const socket = sockets.get(`${type}${i}`);

        if (socket) {
          if (socket.hasConnection()) {
            socket.connections.forEach((c: Connection) =>
              editor.removeConnection(c)
            );
          }

          if (type === 'output') {
            node.removeOutput(socket as Output);
          } else {
            node.removeInput(socket as Input);
          }
        }
      }
    }

    await node.update();
    editor.view.updateConnections({ node });
  }
}

const plugin = { name: 'DynamicSockets', install };

export default plugin;
