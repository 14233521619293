import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  createTheme,
  Input,
  Loader,
  Paper,
  Text,
  Title,
} from '@mantine/core';

import buttonClassNames from './styles/mantine/Button.module.scss';
import inputClassNames from './styles/mantine/Input.module.scss';

const fontFamily = "'Roboto', 'Helvetica Neue', 'Arial', sans-serif";

export const LUMEO_THEME = createTheme({
  fontFamily,

  respectReducedMotion: true,

  colors: {
    blue: [
      '#e3faff',
      '#cfefff',
      '#a0dcfa',
      '#6fc9f6',
      '#48b8f3',
      '#31aef1',
      '#1fa9f2',
      '#0b94d8',
      '#0084c2',
      '#0072ac',
    ],
  },
  primaryColor: 'blue',
  primaryShade: 7,

  fontSizes: {
    xs: 'var(--text-sm)',
    sm: 'var(--text-md)',
    md: 'var(--text-lg)',
    lg: 'var(--text-xl)',
    xl: 'var(--text-2xl)',
  },
  headings: {
    fontFamily,
    fontWeight: '500',
    sizes: {
      h1: {
        fontSize: 'var(--text-2xl)',
        lineHeight: 'var(--lh-2xl)',
      },
      h2: {
        fontSize: 'var(--text-xl)',
        lineHeight: 'var(--lh-xl)',
      },
      h3: {
        fontSize: 'var(--text-lg)',
        lineHeight: 'var(--lh-lg)',
      },
      h4: {
        fontSize: 'var(--text-md)',
        lineHeight: 'var(--lh-md)',
      },
    },
  },

  radius: {
    xs: 'var(--radius-xs)',
    sm: 'var(--radius-sm)',
    md: 'var(--radius-md)',
    lg: 'var(--radius-lg)',
    xl: 'var(--radius-full)',
  },

  defaultRadius: 'md',

  spacing: {
    xs: 'var(--size-xs)',
    sm: 'var(--size-sm)',
    md: 'var(--size-md)',
    lg: 'var(--size-lg)',
    xl: 'var(--size-xl)',
  },

  components: {
    ActionIcon: ActionIcon.extend({
      classNames: buttonClassNames,
      defaultProps: {
        radius: 'xl',
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        c: 'blue',
      },
    }),
    Button: Button.extend({
      classNames: buttonClassNames,
      defaultProps: {
        size: 'md',
        variant: 'default',
        radius: 'xl',
        fw: 500,
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        color: 'gray',
        variant: 'light',
        tt: 'capitalize',
        fw: 500,
        lh: 1,
        size: 'lg',
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: 'lg',
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        color: 'blue.6',
      },
    }),
    Input: Input.extend({
      classNames: inputClassNames,
    }),
    Paper: Paper.extend({
      defaultProps: {
        radius: 'lg',
        p: 'md',
      },
    }),
    Title: Title.extend({
      defaultProps: {
        c: 'hsl(var(--ui-text-highContrast))',
      },
    }),
    Text: Text.extend({
      defaultProps: {
        c: 'hsl(var(--ui-text))',
        size: 'sm',
      },
    }),
  },
});
