import { Pill, PillGroup, Stack } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';

import { ReteInputProps } from 'pipelines/services/rete/controls/input';

import { UpstreamModelLabelsInput } from './Input';

type UpstreamModelLabelsProps = ReteInputProps;

export function UpstreamModelLabels({
  id,
  value: controlledValue,
  onChange,
}: UpstreamModelLabelsProps) {
  const [values, setValues] = useUncontrolled<string[]>({
    value: controlledValue ? controlledValue.split(',') : [],
    defaultValue: [],
    onChange(values) {
      if (!values.length) {
        onChange?.(null);
        return;
      }

      onChange?.(values.join(',').trim());
    },
  });

  function handleRemoveValue(removedValue: string) {
    setValues(values.filter((value) => value !== removedValue));
  }

  return (
    <Stack gap="xs">
      <UpstreamModelLabelsInput
        id={id}
        values={values}
        onSubmitValue={(value) => {
          if (values.includes(value)) {
            return;
          }
          setValues([...values, value]);
        }}
      />

      {values.length > 0 && (
        <PillGroup gap="xs">
          {values.map((value) => (
            <Pill
              key={value}
              onRemove={() => handleRemoveValue(value)}
              withRemoveButton
            >
              {value}
            </Pill>
          ))}
        </PillGroup>
      )}
    </Stack>
  );
}
