import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import Dashboard from 'types/dashboard';

import { useAPI } from 'hooks/api/useAPI';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCreateDashboard } from 'dashboards/hooks/useCreateDashboard';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { FormErrorMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import * as Dialog from 'components/Dialog';

import styles from './CreateDashboardDialog.module.scss';
import {
  getTemplateOptions,
  getDashboardConfigurationForTemplate,
} from './DashboardTemplates';

const c = classNames.bind(styles);

export type CreateDashboardDialogProps = Pick<
  Dialog.RootProps,
  'open' | 'onOpenChange'
>;

type CreateDashboardFieldValues = {
  name: string;
  templateId: string;
};

export function CreateDashboardDialog({
  open,
  onOpenChange,
}: CreateDashboardDialogProps) {
  const redirect = useAppRedirect();
  const { applicationID } = useAPI();
  const { data: currentPlan } = useCurrentPlan();

  const { preset, start, stop, timezone } = useTimeRangeParams();
  const search = new URLSearchParams({
    preset,
    start,
    stop,
    timezone,
  }).toString();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
  } = useForm<CreateDashboardFieldValues>();

  const {
    isLoading: isCreatingDashboard,
    error,
    mutate: createDashboard,
  } = useCreateDashboard({
    onSuccess(dashboard) {
      redirect({
        pathname: `/applications/${applicationID}/monitor/dashboards/${dashboard.id}`,
        search,
      });
      onOpenChange?.(false);
      reset();
    },
  });

  // TODO: missing permissions handling
  if (!currentPlan?.can_create_dashboards) {
    return null;
  }

  function handleOpenChange(open: boolean) {
    reset();
    onOpenChange?.(open);
  }

  function onSubmit({ name, templateId }: CreateDashboardFieldValues) {
    const dashboard = new Dashboard(
      '',
      '',
      name,
      getDashboardConfigurationForTemplate(templateId)
    );

    createDashboard(dashboard);
  }

  return (
    <Dialog.Root
      className={c('dialog')}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Dialog.Title asChild>
        <Heading level="2">Create new dashboard</Heading>
      </Dialog.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorMessage error={error} />

        <Field
          id="dashboard-template"
          label="Template"
          error={errors.templateId}
        >
          <Select
            id="dashboard-template"
            onChange={(selection) => {
              setValue('templateId', selection ? selection.value : '');
              if (!getValues('name')) {
                setValue('name', selection ? selection.label : '');
              }
            }}
            options={getTemplateOptions()}
          />
        </Field>

        <Field
          id="dashboard-name"
          label="Dashboard name"
          error={errors.name}
          required
        >
          <Input
            {...register('name', {
              required: 'Please choose a name for this dashboard.',
            })}
            id="dashboard-name"
            type="text"
            autoComplete="off"
            spellCheck="false"
            autoFocus
          />
        </Field>

        <ButtonGroup align="end">
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button variant="primary" type="submit" loading={isCreatingDashboard}>
            Create
          </Button>
        </ButtonGroup>
      </form>
    </Dialog.Root>
  );
}
