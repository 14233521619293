import classNames from 'classnames/bind';

import { PillList } from 'components/Pill/PillList';
import { Pill } from 'components/Pill/Pill';
import { Text } from 'components/Text/Text';

import styles from './Roles.module.scss';

const c = classNames.bind(styles);

export function RolesOrganizationTableItemSkeleton() {
  return (
    <div className={c('applications-item', 'row')}>
      <Text className={c('skeleton-text')} />
      <PillList>
        <Pill>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Pill>
        <Pill>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Pill>
      </PillList>
    </div>
  );
}
