import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { ApplicationParams } from 'application/views/App/AppContainer';
import { StreamType } from 'types/stream';

import { useCamera } from 'cameras/hooks/useCamera';
import { useGateway } from 'gateways/hooks/useGateway';
import { useHasAccess } from 'hooks/useHasAccess';
import { useStream } from 'streams/hooks/useStream';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';

import { AppLink } from 'components/AppLink/AppLink';
import { DeploymentListSmall } from 'components/DeploymentListSmall/DeploymentListSmall';
import { DetailView, DetailViewHeader } from 'components/DetailView/DetailView';
import { EntityList, EntityListItem } from 'components/EntityList';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Loader } from 'components/Loader/Loader';
import { NotFound } from 'views/NotFound/NotFound';
import { ReactComponent as EmptyIllustration } from 'streams/images/empty-streams-illustration.svg';
import { SharedStreamsList } from 'streams/components/SharedStreams/SharedStreamsList';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';
import { VideoSourceSnapshot } from 'components/VideoSourceSnapshot/VideoSourceSnapshot';

import { StreamDetailActions } from './Actions';
import { StreamDetailSettings } from './Settings';
import styles from './StreamDetail.module.scss';

const c = classNames.bind(styles);

export type StreamParams = ApplicationParams & {
  streamID: string;
};

export function StreamDetail() {
  const { streamID } = useParams<StreamParams>();
  const { data: stream, isLoading } = useStream(streamID);
  const { data: gateway, isLoading: isLoadingGateway } = useGateway(
    stream?.gateway_id
  );
  const { data: camera, isLoading: isLoadingCamera } = useCamera(
    stream?.camera_id
  );
  const { mutate: updateStream, error } = useUpdateStream(['streams']);

  const [hasAccess] = useHasAccess();
  const [isRenaming, toggleIsRenaming] = useToggle();

  function handleRename(name: string) {
    if (!stream) {
      return;
    }

    updateStream(stream.withName(name));
  }

  if (!streamID) {
    return null;
  }

  if (isLoading) {
    return <Loader text="Loading stream..." />;
  }

  if (!stream) {
    return (
      <NotFound entity="stream" image={EmptyIllustration}>
        <p>
          Go to{' '}
          <AppLink to="/deploy/streams" className="link">
            Input Streams
          </AppLink>{' '}
          or{' '}
          <AppLink to="/monitor/webrtc-streams" className="link">
            Output Streams
          </AppLink>
        </p>
      </NotFound>
    );
  }

  return (
    <DetailView
      key={stream.id} // Key is required to correctly re-initialize detail view
      size="default"
    >
      <DetailViewHeader
        entity={stream}
        entityType="streams"
        listQueryKey={['streams']}
        singleQueryKey={['stream']}
        isRenaming={isRenaming}
        onRename={hasAccess('deploy_edit') ? handleRename : undefined}
      >
        {(stream.camera_id || stream.gateway_id) && (
          <EntityList>
            {stream.camera_id && (
              <EntityListItem
                icon="camera"
                label="Camera"
                type="cameras"
                loading={isLoadingCamera}
                entity={camera}
              />
            )}
            {stream.gateway_id && (
              <EntityListItem
                icon="gateway"
                label="Gateway"
                type="gateways"
                loading={isLoadingGateway}
                entity={gateway}
              />
            )}
          </EntityList>
        )}

        <StreamDetailActions
          stream={stream}
          isEditable={hasAccess('deploy_edit')}
          onRename={toggleIsRenaming}
        />
        <div>
          {error && (
            <InlineNotification intent="danger">
              {error.message}
            </InlineNotification>
          )}
        </div>
      </DetailViewHeader>

      <Tabs defaultValue="preview" className={c('tabs')}>
        <TabsList className={c('tabs-list')}>
          <TabsButton value="preview">Preview</TabsButton>
          <TabsButton value="deployments">Deployments</TabsButton>
          <TabsButton value="shared-streams">Shared streams</TabsButton>
          {hasAccess('deploy_edit') &&
            stream.stream_type === StreamType.RTSP && (
              <TabsButton value="settings">Settings</TabsButton>
            )}
        </TabsList>

        <TabsContent className={c('tab-content')} value="preview">
          <div className={c('preview')}>
            <VideoSourceSnapshot source={stream} hideTitle />
          </div>
        </TabsContent>

        <TabsContent className={c('tab-content')} value="deployments">
          <DeploymentListSmall streamIds={[streamID]} />
        </TabsContent>

        <TabsContent className={c('tab-content')} value="shared-streams">
          <SharedStreamsList
            params={{ stream_ids: [stream.id] }}
            defaultStream={stream}
          />
        </TabsContent>

        {hasAccess('deploy_edit') && stream.stream_type === StreamType.RTSP && (
          <TabsContent className={c('tab-content')} value="settings">
            <StreamDetailSettings stream={stream} />
          </TabsContent>
        )}
      </Tabs>
    </DetailView>
  );
}
