import { CellProps, Hooks } from 'react-table';

import { Checkbox } from 'components/Checkbox/Checkbox';

export function useCheckboxColumn<T extends object>(hooks: Hooks<T>) {
  hooks.visibleColumns.push((columns, { instance }) => {
    const { bulkEdit, tableId = '', checkboxClassName = '' } = instance;

    if (!bulkEdit) {
      return columns;
    }

    return [
      {
        id: 'selection',
        Header({ getToggleAllRowsSelectedProps }) {
          return (
            <Checkbox
              {...getToggleAllRowsSelectedProps()}
              id={`${tableId}-select-all`}
              className={checkboxClassName}
            />
          );
        },
        Cell({ row }: CellProps<T>) {
          return (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              id={`${tableId}-${row.id}`}
              className={checkboxClassName}
            />
          );
        },
        width: 0,
      },
      ...columns,
    ];
  });
}

useCheckboxColumn.pluginName = 'useCheckboxColumn';
