import classnames from 'classnames/bind';
import { FilterOperator, Sort } from '@propeldata/ui-kit';
import { useParams } from 'react-router-dom';

import {
  isPropelGatewayMetric,
  PROPEL_GATEWAY_METRICS_LATEST,
  PropelGatewayMetric,
} from 'gateways/services/propel_gateway_metrics';

import { useMetricReport } from 'graphql/hooks/useMetricReport';

import { Pill } from 'components/Pill/Pill';

import { GatewayParams } from './GatewayDetail';
import styles from './UtilizationIndicators.module.scss';

const c = classnames.bind(styles);

export function GatewayDetailHardwareUtilizationIndicators() {
  const { gatewayID } = useParams<GatewayParams>();

  const { data } = useMetricReport({
    metrics: PROPEL_GATEWAY_METRICS_LATEST,
    dimensions: [{ columnName: 'timestamp', sort: Sort.Desc }],
    filters: [
      {
        column: 'gateway_id',
        operator: FilterOperator.Equals,
        value: gatewayID,
      },
    ],
    orderByColumn: 1,
    first: 1,
  });

  if (!data) {
    return null;
  }

  const {
    metricReport: { headers, rows },
  } = data;

  if (!rows.length) {
    return null;
  }

  const [row] = rows;

  const values = Object.fromEntries(
    headers.map((header, index) => [
      header,
      isPropelGatewayMetric(header)
        ? String(Math.round(Number(row[index])))
        : row[index],
    ])
  ) as Record<PropelGatewayMetric | 'timestamp', string>;

  const high = 89;

  const hasHighCpuUsage =
    Number(values['Gateway CPU Utilization Latest']) > high ||
    Number(values['Gateway CPU Memory Usage Percent Latest']) > high;
  const hasHighGpuUsage =
    Number(values['Gateway GPU Utilization Latest']) > high ||
    Number(values['Gateway GPU Memory Usage Percent Latest']) > high;
  const hasHighStorageUsage =
    Number(values['Gateway Disk Usage Percent Latest']) > high;

  return (
    <div className={c('utilization-indicators')}>
      <div className={c('metric')}>
        <Pill
          className={c('pill')}
          intent={hasHighCpuUsage ? 'danger' : 'success'}
          icon={hasHighCpuUsage ? 'danger' : 'check'}
        />
        <span>CPU</span>
      </div>
      <div className={c('metric')}>
        <Pill
          className={c('pill')}
          intent={hasHighGpuUsage ? 'danger' : 'success'}
          icon={hasHighGpuUsage ? 'danger' : 'check'}
        />
        <span>GPU</span>
      </div>
      <div className={c('metric')}>
        <Pill
          className={c('pill')}
          intent={hasHighStorageUsage ? 'danger' : 'success'}
          icon={hasHighStorageUsage ? 'danger' : 'check'}
        />
        <span>Storage</span>
      </div>
    </div>
  );
}
