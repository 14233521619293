import { SingleValue } from 'react-select';

import Gateway from 'types/gateway';
import { FilterInput } from 'graphql/types/filter';

import { useGateways } from 'hooks/api/useGateways';

import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';

import { Placeholder } from './SuggestionInput';
import { filterIds } from '../service';
import { useMetricFiltersState } from '../context';

export type GatewayFilterInputProps = Pick<FilterInput, 'id' | 'value'>;

export function GatewayFilterInput({ id, value }: GatewayFilterInputProps) {
  const { filters, updateFilter } = useMetricFiltersState();

  const gatewayIds = filterIds('gateway_id', filters);

  const { data: gateways, isInitialLoading } = useGateways(
    ['metric-filter-gateways'],
    { gateway_ids: gatewayIds },
    { enabled: Boolean(gatewayIds.length), keepPreviousData: true }
  );

  if (!gateways && isInitialLoading) {
    return Placeholder;
  }

  function handleGatewayChange(gateway: SingleValue<Gateway>) {
    updateFilter(id, 'value', gateway?.id);
  }

  return (
    <GatewaySelect
      defaultValue={gateways?.data.find(({ id }) => id === value)}
      onChange={handleGatewayChange}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: 'auto',
        }),
      }}
    />
  );
}
