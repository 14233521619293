import classNames from 'classnames/bind';
import { CellProps } from 'react-table';

import LumeodMetric from 'types/metric';

import { Heading } from 'components/Heading/Heading';
import { Text } from 'components/Text/Text';

import styles from '../Settings.module.scss';

const c = classNames.bind(styles);

export type MetricsConfigTableSummaryProps = CellProps<LumeodMetric>;

export function MetricsConfigTableSummary({
  row,
}: MetricsConfigTableSummaryProps) {
  return (
    <>
      <Heading level="4" asChild>
        <strong>{row.original.name}</strong>
      </Heading>
      <Text type="paragraph" className={c('config-table-summary-description')}>
        {row.original.description}
      </Text>
    </>
  );
}
