import classNames from 'classnames/bind';
import { UseQueryResult } from '@tanstack/react-query';

import AIModel from 'types/ai_model';
import APIError from 'types/api_error';
import Account from 'types/account';
import Camera from 'types/camera';
import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import Stream from 'types/stream';
import { FilesSearchResult } from 'types/files_search_result';
import { PaginatedAPIResponse } from 'types/api';

import OrganizationInvitation from 'types/invitation';

import { pluralize } from 'services/string';

import { Separator } from 'components/Separator/Separator';
import { Text } from 'components/Text/Text';

import { QuerySearchInput } from './QuerySearchInput';
import styles from './QuerySearch.module.scss';

const c = classNames.bind(styles);

export type SearchableEntity =
  | Account
  | Camera
  | Deployment
  | Gateway
  | AIModel
  | Pipeline
  | Stream
  | FilesSearchResult
  | OrganizationInvitation;

export type QuerySearchProps<T extends SearchableEntity> = {
  className?: string;
  value?: string;
  /** Layout */
  orientation?: 'horizontal' | 'vertical';

  entityName: string;
  searchLabel?: string;
  totalElements?: number;
  filters?: JSX.Element;
  actions?: JSX.Element;
  onApplyFilters?: () => void;
  onValueChange: (value?: string) => void;
} & (
  | {
      /** Search field will not yield suggestions if undefined. */
      searchQueryResult?: never;
      searchResultField?: never;
      onSuggestionSelect?: never;
    }
  | {
      searchQueryResult: UseQueryResult<PaginatedAPIResponse<T>, APIError>;
      /** Field that is displayed in the search results */
      searchResultField: keyof T;
      onSuggestionSelect?: (suggestion: T) => void;
    }
);

export function QuerySearch<T extends SearchableEntity>({
  className,
  value,
  orientation = 'horizontal',
  entityName,
  searchLabel,
  searchResultField,
  searchQueryResult,
  totalElements,
  filters,
  actions,
  onApplyFilters,
  onSuggestionSelect,
  onValueChange,
}: QuerySearchProps<T>) {
  return (
    <div className={c('search-header', className, orientation)}>
      <QuerySearchInput
        value={value}
        searchLabel={searchLabel}
        searchResultField={searchResultField}
        searchQueryResult={searchQueryResult}
        onApplyFilters={onApplyFilters}
        onSuggestionSelect={onSuggestionSelect}
        onValueChange={onValueChange}
      />

      {filters && (
        <>
          {orientation === 'horizontal' && <Separator orientation="vertical" />}

          <div className={c('filters')}>{filters}</div>
        </>
      )}

      {(!filters || orientation === 'vertical') && totalElements ? (
        <>
          {orientation === 'horizontal' && <Separator orientation="vertical" />}

          <Text>
            {totalElements} {pluralize(totalElements, entityName)}
          </Text>
        </>
      ) : null}

      {actions && <div className={c('actions')}>{actions}</div>}
    </div>
  );
}
