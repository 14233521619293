import classNames from 'classnames/bind';
import { Skeleton } from '@mantine/core';

import { Tag } from 'types/tag';

import downloadFile from 'services/download_file';
import { useCamera } from 'cameras/hooks/useCamera';
import { useFile } from 'files/hooks/useFile';
import { useModal } from 'hooks/useModal';
import { usePipeline } from 'hooks/api/usePipeline';
import { useStream } from 'streams/hooks/useStream';
import { useAddEntityTags } from 'tags/hooks/useAddEntityTag';
import { useDeleteEntityTags } from 'tags/hooks/useDeleteEntityTags';

import { Button } from 'components/Button/Button';
import { EntityList, EntityListItem } from 'components/EntityList';
import { FileDetailDescription } from 'files/views/FileDetail/Description';
import { FileDetailPreview } from 'files/views/FileDetail/Preview';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Tabs, TabsButton, TabsContent, TabsList } from 'components/Tabs/Tabs';
import { TagSelect } from 'tags/components/TagSelect/TagSelect';

import styles from './FilesSearchDetail.module.scss';

const c = classNames.bind(styles);

type FilesSearchDetailProps = {
  id: string;
};

export function FilesSearchDetail({ id }: FilesSearchDetailProps) {
  const { close } = useModal();

  const { data: file, isLoading: isLoadingFile } = useFile(id);

  const { data: stream, isInitialLoading: isLoadingStream } = useStream(
    file?.stream_id
  );
  const { data: camera, isInitialLoading: isLoadingCamera } = useCamera(
    stream?.camera_id
  );
  const { data: pipeline, isInitialLoading: isLoadingPipeline } = usePipeline(
    file?.pipeline_id
  );

  const { mutate: addTags } = useAddEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  const { mutate: removeTags } = useDeleteEntityTags({
    entity: 'files',
    listQueryKey: ['files'],
    singleQueryKey: ['file'],
  });

  function handleAddTags(tag: Tag) {
    if (!file) {
      return;
    }

    addTags({ id: file.id, tagIds: [tag.id] });
  }

  function handleRemoveTags(tagId: Tag['id']) {
    if (!file) {
      return;
    }

    removeTags({ id: file.id, tagIds: [tagId] });
  }

  return (
    <div className={c('file-detail')}>
      <div className={c('header')}>
        {isLoadingFile ? (
          <Skeleton height="var(--size-lg)" width="80%" />
        ) : (
          <Heading level="1" className={c('heading')}>
            {file?.name}
          </Heading>
        )}

        {stream && (
          <EntityList className={c('entities')}>
            <EntityListItem
              icon="stream"
              label="Stream"
              type="streams"
              loading={isLoadingStream}
              entity={stream}
              onLinkClick={() => close()}
            />
            <EntityListItem
              icon="camera"
              type="cameras"
              label="Camera"
              loading={isLoadingCamera}
              entity={camera}
              onLinkClick={() => close()}
            />
            <EntityListItem
              icon="pipeline"
              type="pipelines"
              label="Pipeline"
              loading={isLoadingPipeline}
              entity={pipeline}
              onLinkClick={() => close()}
            />
          </EntityList>
        )}

        <IconButton
          className={c('button')}
          icon="download"
          label="Download"
          size="small"
          variant="secondary"
          loading={isLoadingFile}
          onClick={() =>
            file && file.data_url && downloadFile(file.name, file.data_url)
          }
          disabled={!file?.data_url}
          showLabel
        />

        {file && (
          <TagSelect
            defaultSelection={file.tags}
            className={c('tags')}
            trigger={
              <Button size="xsmall" variant="secondary">
                Edit labels
              </Button>
            }
            onAdd={handleAddTags}
            onRemove={handleRemoveTags}
          />
        )}
      </div>
      <Tabs defaultValue="preview">
        <TabsList>
          <TabsButton value="preview">Preview</TabsButton>
        </TabsList>

        <TabsContent value="preview" className={c('preview')}>
          <FileDetailPreview
            url={file?.data_url}
            mimeType={file?.mime_type}
            cloudStatus={file?.cloud_status}
            isLoading={isLoadingFile}
          />
          {file && <FileDetailDescription file={file} />}
        </TabsContent>
      </Tabs>
    </div>
  );
}
