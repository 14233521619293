import classNames from 'classnames/bind';

import { HelpTooltip } from 'components/HelpTooltip/HelpTooltip';
import { Kbd } from 'components/Kbd/Kbd';

import arrowRight from 'pipelines/images/arrow_right.svg';
import arrowLeft from 'pipelines/images/arrow_left.svg';

import styles from './Tooltip.module.scss';

const c = classNames.bind(styles);

type ROITooltipProps = {
  typeLabel: string;
};

export function ROITooltip({ typeLabel }: ROITooltipProps) {
  const typeLabelPlural = `${typeLabel}s`;

  return (
    <HelpTooltip
      content={
        <>
          <p className={c('help-paragraph')}>
            Draw {typeLabelPlural} on the canvas above by clicking to create
            points in the desired locations.
          </p>
          <strong>Keyboard shortcuts</strong>
          <ol className={c('help-tips')}>
            <li>
              <strong>Double-click</strong> to complete a {typeLabel} in place.
            </li>
            <li>
              <Kbd>Enter</Kbd> to complete a {typeLabel}
            </li>
            <li>
              <Kbd>ESC</Kbd> to cancel drawing and remove the unfinished{' '}
              {typeLabel}
            </li>
            <li>
              <Kbd>Backspace</Kbd> to remove the last segment while drawing
            </li>
            <li>
              <Kbd>Backspace</Kbd> to delete a selected {typeLabel}
            </li>
          </ol>
          <p className={c('help-paragraph')}>
            Tip: Use a current snapshot from a linked video source as a
            guideline.
          </p>
          <p className={c('help-indicators')}>
            <img src={arrowRight} alt="" aria-labelledby="roi_direction-1" />
            <span id="roi_direction-1">Direction 1</span>
            <img src={arrowLeft} alt="" aria-labelledby="roi_direction-2" />
            <span id="roi_direction-2">Direction 2</span>
          </p>
        </>
      }
      side="bottom"
    />
  );
}
