import AIModel from 'types/ai_model';

import { useModels } from 'hooks/api/useModels';

import * as PageLayout from 'components/PageLayout';
import { AdvancedSearch } from 'components/AdvancedSearch/AdvancedSearch';
import { ModelGrid } from 'analytics-library/components/ModelGrid/ModelGrid';

const EMPTY_MODELS: AIModel[] = [];

export function AIModels() {
  const { data: models, isLoading } = useModels();

  return (
    <PageLayout.Root>
      <AdvancedSearch
        data={models || EMPTY_MODELS}
        isLoading={isLoading}
        searchLabel="Search models"
        entity="models"
      >
        <ModelGrid />
      </AdvancedSearch>
    </PageLayout.Root>
  );
}
