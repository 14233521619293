import classNames from 'classnames/bind';

import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { MeterValue } from 'components/Meter';

import styles from '../OrganizationUtilization.module.scss';

const c = classNames.bind(styles);

export function WorkspaceUtilizationCard() {
  const { data: utilization } = useUsageStatistics();
  const { data: currentPlan } = useCurrentPlan();

  if (!utilization || currentPlan === undefined) {
    return null;
  }

  const value = utilization.application_count;
  const max = currentPlan?.application_limit;

  return (
    <Card>
      <Heading level="4" asChild>
        <h3>Workspaces</h3>
      </Heading>

      <div className={c('meter-value')}>
        <MeterValue value={value} max={max} />
      </div>
    </Card>
  );
}
