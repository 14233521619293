import classNames from 'classnames/bind';

import Gateway from 'types/gateway';

import { CreateInputStreamsForm } from 'streams/components/CreateInputStreamsDialog/CreateInputStreamsForm';
import { Drawer, DrawerProps } from 'components/Drawer/Drawer';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { StreamTypeInfo } from 'streams/components/StreamTypeInfo/StreamTypeInfo';
import { Text } from 'components/Text/Text';

import styles from './CreateInputStreamsDialog.module.scss';

const c = classNames.bind(styles);

export type CreateInputStreamsDialogProps = DrawerProps & {
  gateway?: Gateway;
  onSuccess?: () => void;
};

export function CreateInputStreamsDialog({
  open,
  gateway,
  onSuccess,
  onClose,
}: CreateInputStreamsDialogProps) {
  return (
    <Drawer
      title="Add live streams"
      open={open}
      onClose={onClose}
      classNames={{ content: c('drawer') }}
    >
      <Text type="paragraph">
        Add RTSP or HTTP streams to Lumeo to use as a video source for analytic
        pipelines.
      </Text>
      <ExternalLink href="https://docs.lumeo.com/docs/camera-setup-guide#stream-setup">
        Stream setup guide
      </ExternalLink>
      <StreamTypeInfo gateway={gateway} onClose={onClose} />
      <CreateInputStreamsForm
        gateway={gateway}
        onSuccess={() => {
          onClose?.();
          onSuccess?.();
        }}
      />
    </Drawer>
  );
}
