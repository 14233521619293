import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Account from 'types/account';
import Organization from 'types/organization';

import { useOrganizationApplications } from 'hooks/api/useApplications';

import { Box, BoxError } from 'components/Box/Box';
import { Heading } from 'components/Heading/Heading';
import { Pill } from 'components/Pill/Pill';
import { PillList } from 'components/Pill/PillList';
import { SkeletonList } from 'components/SkeletonList/SkeletonList';
import { Text } from 'components/Text/Text';

import { RolesOrganizationTableItemSkeleton } from './OrganizationTableItemSkeleton';
import styles from './Roles.module.scss';

const c = classNames.bind(styles);

export type RolesOrganizationTableProps = {
  organization: Organization;
  account: Account;
};

export function RolesOrganizationTable({
  organization,
  account,
}: RolesOrganizationTableProps) {
  const {
    data: applications,
    isLoading: isLoadingApplications,
    error,
  } = useOrganizationApplications(organization.id);

  const organizationRoles = account.roles.filter(
    ({ organization_id }) => organization_id === organization.id
  );

  return (
    <Box className={c('organization')}>
      <header className={c('organization-header', 'row')}>
        <Heading level="4">
          <span>{organization.name}</span>
        </Heading>

        <PillList className={c('roles')}>
          {organizationRoles.map(({ name }) => (
            <Pill key={name}>{name}</Pill>
          ))}
        </PillList>
      </header>

      <BoxError error={error} />

      {isLoadingApplications && (
        <SkeletonList component={<RolesOrganizationTableItemSkeleton />} />
      )}

      {applications && (
        <dl className={c('applications')}>
          {applications.map(({ id, name }) => {
            const appRoles = account.roles.filter(
              ({ application_id }) => application_id === id
            );

            return (
              <div className={c('applications-item', 'row')} key={id}>
                <dt>
                  <Text>
                    <Link to={`/applications/${id}`}>{name}</Link>
                  </Text>
                </dt>

                <dd className={c('applications-roles')}>
                  <PillList className={c('roles')}>
                    {appRoles.map(({ name }) => (
                      <Pill key={name}>{name}</Pill>
                    ))}
                  </PillList>
                </dd>
              </div>
            );
          })}
        </dl>
      )}
    </Box>
  );
}
