import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, TimeSeriesScale } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import 'chartjs-adapter-luxon';
import { TimeRangeInput, TimeSeriesGranularity } from '@propeldata/ui-kit';

import { CustomTimeSeriesQueryVariables } from 'graphql/queries/timeseries';
import { FilterInput } from 'graphql/types/filter';

import { useDashboardWidgetParams } from 'dashboards/hooks/useDashboardWidgetParams';
import { useHeatmapChart } from 'dashboards/hooks/useHeatmapChart';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';

ChartJS.register(
  MatrixController,
  MatrixElement,
  CategoryScale,
  TimeSeriesScale
);

export const HEATMAP_CHART_GRANULARITIES = [
  TimeSeriesGranularity.Day,
  TimeSeriesGranularity.Hour,
];

// Propel components use 'metric' instead of 'metricName' so we keep the same behaviour here
export type HeatmapChartQuery = Pick<
  CustomTimeSeriesQueryVariables,
  'granularity' | 'dimensions'
> & {
  metric: string;
  timeRange?: TimeRangeInput;
  filters?: FilterInput[];
};

export type HeatmapChartProps = { query: HeatmapChartQuery };

export function HeatmapChart({ query }: HeatmapChartProps) {
  const params =
    useDashboardWidgetParams<CustomTimeSeriesQueryVariables>(query);

  const { seriesLabels, data, isLoading, error } = useHeatmapChart(params);

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (error) {
    return (
      <ChartError
        error={{
          title: 'No data',
          body: error,
        }}
      />
    );
  }

  if (!data) {
    return null;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category' as const,
        labels: data?.labels,
        offset: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'category' as const,
        labels: seriesLabels,
        offset: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        callbacks: {
          // @ts-ignore the `matrix` chart type uses the `v` property but it's not defined in the types so we can't use explicit types here
          label(context) {
            const dataPoint = context.dataset.data[context.dataIndex];
            return [`${dataPoint.y}: ${dataPoint.v}`];
          },
        },
      },
    },
  };

  return <Chart type="matrix" data={data} options={options} />;
}
