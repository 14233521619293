import classnames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { ApplicationParams } from 'application/types/application_params';

import downloadFile from 'services/download_file';
import { useDeleteFileStreams } from 'files/hooks/useDeleteFileStreams';
import { useFile } from 'files/hooks/useFile';
import { useGateway } from 'gateways/hooks/useGateway';
import { useHasAccess } from 'hooks/useHasAccess';
import { useMqttStreamsState } from 'streams/hooks/useMqttStreamsState';
import { useStream } from 'streams/hooks/useStream';
import { useUpdateStream } from 'streams/hooks/useUpdateStream';

import { DeploymentListSmall } from 'components/DeploymentListSmall/DeploymentListSmall';
import {
  DetailView,
  DetailViewHeader,
  DetailViewStatus,
} from 'components/DetailView/DetailView';
import { EntityList, EntityListItem } from 'components/EntityList';
import { IconButton } from 'components/IconButton/IconButton';
import { Loader } from 'components/Loader/Loader';
import { NotFound } from 'views/NotFound/NotFound';
import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';
import { Tabs, TabsButton, TabsList, TabsContent } from 'components/Tabs/Tabs';

import { FileDetailDescription } from './Description';
import { FileDetailPreview } from './Preview';
import { FileDetailConfiguration } from './Configuration';

import styles from './FileDetail.module.scss';

const c = classnames.bind(styles);

export type FileParams = ApplicationParams & {
  fileID: string;
};

export function FileDetail() {
  const navigate = useNavigate();

  const { applicationID, fileID } = useParams<FileParams>();

  const { data: fileStream, isInitialLoading } = useStream(fileID);
  const { data: file, isInitialLoading: isLoadingFile } = useFile(
    fileStream?.fileID
  );
  const { data: gateway, isInitialLoading: isLoadingGateway } = useGateway(
    fileStream?.gateway_id
  );

  useMqttStreamsState(fileID ? [fileID] : []);

  const { mutate: updateFile } = useUpdateStream(['streams']);
  const [requestDelete, { isLoading: isDeleting }] = useDeleteFileStreams({
    onSuccess() {
      navigate('./..');
    },
  });

  const [hasAccess] = useHasAccess();

  const [isRenaming, toggleIsRenaming] = useToggle();

  const isURIFileStream =
    fileStream?.uri && !fileStream.uri.startsWith('lumeo://');

  if (isInitialLoading) {
    return <Loader text="Loading file..." />;
  }

  if (!fileStream) {
    return (
      <NotFound
        entity="file"
        returnTo={`/applications/${applicationID}/deploy/files`}
      />
    );
  }

  function handleRename(name: string) {
    if (!fileStream) {
      return;
    }

    updateFile(fileStream.withName(name));
  }

  function handleDeleteClick() {
    if (!fileStream) {
      return;
    }

    requestDelete([fileStream.id]);
  }

  function handleDownloadClick() {
    if (!file?.data_url) {
      return;
    }

    downloadFile(file?.name, file?.data_url);
  }

  return (
    <DetailView
      key={fileStream.id} // Key is required to correctly re-initialize detail view
      size="default"
    >
      <DetailViewHeader
        entity={fileStream}
        entityType="streams"
        singleQueryKey={['stream']}
        listQueryKey={['streams']}
        isRenaming={isRenaming}
        onRename={hasAccess('deploy_edit') ? handleRename : undefined}
      >
        {fileStream.gateway_id && (
          <EntityList>
            <EntityListItem
              icon="gateway"
              label="Gateway"
              type="gateways"
              loading={isLoadingGateway}
              entity={gateway}
            />
          </EntityList>
        )}

        <DetailViewStatus entity={fileStream} onRename={toggleIsRenaming}>
          <StatusIndicator status={fileStream.status} />

          <IconButton
            icon="download"
            label="Download"
            size="small"
            variant="secondary"
            onClick={handleDownloadClick}
            showLabel
          />

          {hasAccess('deploy_edit') && (
            <IconButton
              icon="delete"
              label="Delete"
              onClick={handleDeleteClick}
              intent="danger"
              loading={isDeleting}
              size="small"
              variant="secondary"
              showLabel
            />
          )}
        </DetailViewStatus>
      </DetailViewHeader>

      <Tabs defaultValue="preview" className={c('tabs')}>
        <TabsList className={c('tabs-list')}>
          <TabsButton value="preview">Preview</TabsButton>
          {isURIFileStream && (
            <TabsButton value="configuration">Configuration</TabsButton>
          )}
          <TabsButton value="deployments">Deployments</TabsButton>
        </TabsList>

        <TabsContent className={c('tab-content')} value="preview">
          <div className={c('preview-wrap')}>
            <FileDetailPreview
              url={file?.data_url ?? fileStream.uri}
              mimeType={file?.mime_type}
              isLoading={isLoadingFile}
            />

            {file && <FileDetailDescription file={file} />}
          </div>
        </TabsContent>

        {isURIFileStream && (
          <TabsContent className={c('tab-content')} value="configuration">
            <FileDetailConfiguration fileStream={fileStream} />
          </TabsContent>
        )}

        <TabsContent className={c('tab-content')} value="deployments">
          <DeploymentListSmall streamIds={[fileStream.id]} />
        </TabsContent>
      </Tabs>
    </DetailView>
  );
}
