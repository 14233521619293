import classNames from 'classnames/bind';
import { filesize } from 'filesize';

import { Text } from 'components/Text/Text';

import styles from './Meter.module.scss';

const c = classNames.bind(styles);

export type MeterValueProps = {
  className?: string;
  value: number;
  unit?: string | 'filesize';
  max?: number | null;
};

const numberFormat = new Intl.NumberFormat('en-us');

function getDisplayValue(value: number, unit: MeterValueProps['unit']) {
  if (unit === 'filesize') {
    return filesize(value, { base: 2 });
  }

  return numberFormat.format(value);
}

function getDisplayMax(
  max: number | null | undefined,
  unit: MeterValueProps['unit']
) {
  if (!max) {
    return null;
  }

  if (!unit) {
    return numberFormat.format(max);
  }

  if (unit === 'filesize') {
    return filesize(max, { base: 2 });
  }

  return `${numberFormat.format(max)} ${unit}`;
}

export function MeterValue({ className, value, max, unit }: MeterValueProps) {
  const displayValue = getDisplayValue(value, unit);
  const displayMax = getDisplayMax(max, unit) ?? 'unlimited';

  return (
    <div className={c(className, 'meter-value')}>
      <span className={c('meter-value-current')}>{displayValue}</span>
      <Text inline>/ {displayMax}</Text>
    </div>
  );
}
