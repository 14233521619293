import React from 'react';
import classNames from 'classnames/bind';
import RGL, { WidthProvider } from 'react-grid-layout';
import { DateTime } from 'luxon';
import { DatesRangeValue } from '@mantine/dates';
import { useDisclosure, useToggle } from '@mantine/hooks';
import { useLocation, useParams } from 'react-router-dom';

import DashboardConfiguration from 'types/dashboard_configuration';
import Gateway from 'types/gateway';
import { DASHBOARD_COLUMNS } from 'types/dashboard';
import { DashboardParams } from 'dashboards/types/dashboard_params';
import { DashboardWidget } from 'types/dashboard_widget';

import {
  adjustTZAndKeepLocalTime,
  useTimeRangeParams,
} from 'dashboards/hooks/useTimeRangeParams';
import { RelativeDateRange } from 'dashboards/services/time_range';
import { useAppRedirect } from 'hooks/useAppRedirect';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useDashboard } from 'dashboards/hooks/useDashboard';
import { useDashboardFilterParams } from 'dashboards/hooks/useDashboardFilterParams';
import { useGateway } from 'gateways/hooks/useGateway';
import { useSpringState } from 'hooks/useSpringState';
import { useUpdateDashboard } from 'dashboards/hooks/useUpdateDashboard';

import { NotFound } from 'views/NotFound/NotFound';

import * as PageLayout from 'components/PageLayout';
import {
  AddWidgetModal,
  EditWidgetModal,
  WidgetModalContext,
} from 'dashboards/components/WidgetModal';
import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import {
  DateRange,
  DateRangePickerInput,
} from 'components/DateRangePickerInput/DateRangePickerInput';
import { EmptyView } from 'components/EmptyView/EmptyView';
import { GatewaySelect } from 'gateways/components/GatewaySelect/GatewaySelect';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { InlineNotification } from 'components/InlineNotification/InlineNotification';
import { Loader } from 'components/Loader/Loader';
import { ReactComponent as EmptyIllustration } from 'dashboards/images/dashboard-empty-illustration.svg';
import { TimezoneSelect } from 'components/TimezoneSelect/TimezoneSelect';

import { DashboardData } from './components/Data/Data';
import { DashboardFiles } from './components/Files/Files';
import { DashboardIframe } from './components/Iframe/Iframe';
import { DashboardStream } from './components/Stream/Stream';
import { DashboardMultiStream } from './components/MultiStream/MultiStream';
import { DashboardWidgetProps } from './types/widget_props';
import styles from './DashboardView.module.scss';

const c = classNames.bind(styles);

const ReactGridLayout = WidthProvider(RGL);

const DASHBOARD_WIDGET_MAP: {
  [key: string]: (props: DashboardWidgetProps<any>) => JSX.Element | null;
} = {
  data: DashboardData,
  iframe: DashboardIframe,
  stream: DashboardStream,
  multistream: DashboardMultiStream,
  files: DashboardFiles,
};

export function DashboardView() {
  const redirect = useAppRedirect();
  const { pathname } = useLocation();
  const { dashboardID } = useParams<DashboardParams>();
  const { data: currentPlan, isLoading: isLoadingPlan } = useCurrentPlan();

  const { preset, start, stop, timezone } = useTimeRangeParams();
  const { gateway_id } = useDashboardFilterParams();
  const { data: selectedGateway, isInitialLoading: isLoadingSelectedGateway } =
    useGateway(gateway_id);

  const datePickerDefault: DateRange =
    preset === 'CUSTOM'
      ? [DateTime.fromISO(start).toJSDate(), DateTime.fromISO(stop).toJSDate()]
      : (preset as RelativeDateRange);

  const gridRef = React.useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useToggle();

  const [
    isAddingWidget,
    { open: openAddWidgetDrawer, close: closeAddWidgetDrawer },
  ] = useDisclosure();

  const [didSave, setDidSave] = useSpringState(false, 2000);
  const [editWidget, setEditWidget] = React.useState<DashboardWidget>();

  const [isEditingLayout, toggleIsEditingLayout] = useToggle();
  const [newLayout, setNewLayout] = React.useState<RGL.Layout[]>();

  const { mutate, isLoading: isSaving } = useUpdateDashboard({
    onSuccess() {
      setDidSave(true);
      toggleIsEditingLayout(false);
    },
  });

  const { data: dashboard, isLoading, error } = useDashboard(dashboardID);

  function handleTimeRangeChange(dateRange?: DatesRangeValue, preset?: string) {
    if (!dateRange || !preset) {
      return;
    }

    const [from, to] = dateRange;

    if (!from || !to) {
      return;
    }

    const start = adjustTZAndKeepLocalTime(
      preset,
      timezone,
      DateTime.fromJSDate(from)
    );
    const stop = adjustTZAndKeepLocalTime(
      preset,
      timezone,
      undefined,
      DateTime.fromJSDate(to)
    );

    if (!start || !stop) {
      return;
    }

    const search = new URLSearchParams({
      preset,
      start,
      stop,
      timezone,
    }).toString();

    redirect({ pathname, search });
  }

  function handleTimezoneChange(timezone?: string) {
    if (!timezone || !start || !stop || !preset) {
      return;
    }

    // Replace timezone on start and stop dates by throwing away
    // the current timezone.
    const startNewTz = adjustTZAndKeepLocalTime(
      preset,
      timezone,
      DateTime.fromISO(start.slice(0, -6))
    );
    const stopNewTz = adjustTZAndKeepLocalTime(
      preset,
      timezone,
      undefined,
      DateTime.fromISO(stop.slice(0, -6))
    );

    if (!startNewTz || !stopNewTz) {
      return;
    }

    const search = new URLSearchParams({
      preset,
      start: startNewTz,
      stop: stopNewTz,
      timezone,
    }).toString();

    redirect({ pathname, search });
  }

  function handleGatewayChange(newGateway: Gateway | null) {
    const search = new URLSearchParams({
      preset,
      start,
      stop,
      timezone,
    });

    if (newGateway) {
      search.append('gateway_id', newGateway.id);
    }

    redirect({ pathname, search: search.toString() });
  }

  if (isLoading || isLoadingPlan) {
    return <Loader text="Loading dashboard..." />;
  }

  if (error || !dashboard || !currentPlan?.can_create_dashboards) {
    return <NotFound entity="dashboard" />;
  }

  const { widgets } = dashboard;

  function saveLayout() {
    if (!dashboard || !newLayout) {
      return;
    }

    const newWidgets = widgets.map((widget) => {
      const matchingLayout = newLayout.find((layout) => layout.i === widget.id);

      if (matchingLayout) {
        widget.layout = matchingLayout;
      }

      return widget;
    });

    const updatedDashboard = dashboard.copy();
    updatedDashboard.configuration = new DashboardConfiguration(newWidgets);

    mutate(dashboard);
  }

  function toggleFullscreen() {
    if (!gridRef.current) {
      return;
    }

    if (isFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
      return;
    }

    gridRef.current.requestFullscreen();
    setIsFullscreen(true);
  }

  function closeEditWidgetDrawer() {
    setEditWidget(undefined);
  }

  function closeDrawers() {
    setEditWidget(undefined);
    closeAddWidgetDrawer();
  }

  function handleEditWidgetClick(id: DashboardWidget['id']) {
    if (!dashboard) {
      return;
    }

    const widget = dashboard.getWidget(id);
    setEditWidget(widget);
  }

  function handleDeleteWidgetClick(id: DashboardWidget['id']) {
    if (!dashboard) {
      return;
    }

    const updatedWidgets = widgets.filter((widget) => widget.id !== id);

    const updatedDashboard = dashboard.copy();
    updatedDashboard.configuration = new DashboardConfiguration(updatedWidgets);
    mutate(updatedDashboard);
  }

  function handleDuplicateWidgetClick(id: DashboardWidget['id']) {
    if (!dashboard) {
      return;
    }

    const widgetToBeDuplicated = widgets.find((widget) => widget.id === id);
    if (!widgetToBeDuplicated) {
      // Not likely to happen but just in case
      return;
    }

    // Get new layout (xy coordinates) for duplicated widget
    const newLayout = dashboard.getNewWidgetLayout(
      widgetToBeDuplicated.layout.w,
      widgetToBeDuplicated.layout.h
    );

    // Duplicate widget with new layout
    const duplicatedWidget = DashboardWidget.duplicate(
      widgetToBeDuplicated,
      newLayout
    );

    mutate(dashboard.upsertWidget(duplicatedWidget));
  }

  const hasWidgets = widgets && widgets.length > 0;
  const layout = !isEditingLayout
    ? widgets.map(({ layout }) => layout)
    : undefined;

  return (
    <PageLayout.Root size="full">
      <PageLayout.Content>
        <div className={c('wrap')} ref={gridRef}>
          <div className={c('controls')}>
            <div className={c('controls-primary')}>
              <DateRangePickerInput
                className={c('controls-time-range')}
                defaultValue={datePickerDefault}
                onChange={handleTimeRangeChange}
                allowSingleDateInRange
                liveUpdate
              />
              <TimezoneSelect
                defaultValue={timezone}
                onChange={handleTimezoneChange}
              />
              <GatewaySelect
                size="small"
                defaultValue={selectedGateway}
                isLoading={isLoadingSelectedGateway}
                onChange={handleGatewayChange}
                isClearable
              />
            </div>

            <ButtonGroup>
              {didSave && (
                <InlineNotification intent="success">Saved!</InlineNotification>
              )}

              <Button
                variant="secondary"
                size="small"
                onClick={openAddWidgetDrawer}
              >
                <Icon name="plus" size="xsmall" />
                <span>Add widget</span>
              </Button>

              {isEditingLayout ? (
                <>
                  <Button
                    size="small"
                    variant="secondary"
                    onClick={() => toggleIsEditingLayout(false)}
                  >
                    Discard changes
                  </Button>
                  <Button
                    size="small"
                    variant="primary"
                    onClick={saveLayout}
                    loading={isSaving}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => toggleIsEditingLayout()}
                  disabled={widgets.length === 0}
                >
                  Edit layout
                </Button>
              )}

              <IconButton
                icon={isFullscreen ? 'collapse' : 'expand'}
                className={c('controls-toggle-fullscreen')}
                label="Toggle fullscreen"
                onClick={toggleFullscreen}
                size="small"
              />
            </ButtonGroup>
          </div>

          <WidgetModalContext.Provider
            value={{ onClose: closeDrawers, editWidget: handleEditWidgetClick }}
          >
            {!hasWidgets && (
              <EmptyView image={EmptyIllustration}>
                No widgets defined in dashboard.
              </EmptyView>
            )}

            {hasWidgets && (
              <ReactGridLayout
                layout={layout}
                className={c({ editable: isEditingLayout })}
                cols={DASHBOARD_COLUMNS}
                containerPadding={[0, 0]}
                isDraggable={isEditingLayout}
                isResizable={isEditingLayout}
                resizeHandles={isEditingLayout ? ['se'] : []}
                onLayoutChange={isEditingLayout ? setNewLayout : undefined}
              >
                {widgets.map((widget) => {
                  const Component = DASHBOARD_WIDGET_MAP[widget.type];

                  if (!Component) {
                    return null;
                  }

                  return (
                    <div
                      className={c('grid-item')}
                      data-grid={widget.layout}
                      key={widget.id}
                    >
                      <div className={c('widget')}>
                        <Component
                          {...widget}
                          onDelete={handleDeleteWidgetClick}
                          onDuplicate={handleDuplicateWidgetClick}
                        />
                      </div>
                    </div>
                  );
                })}
              </ReactGridLayout>
            )}

            <AddWidgetModal
              open={isAddingWidget}
              dashboard={dashboard}
              onClose={closeAddWidgetDrawer}
            />

            <EditWidgetModal
              widget={editWidget}
              dashboard={dashboard}
              onClose={closeEditWidgetDrawer}
            />
          </WidgetModalContext.Provider>
        </div>
      </PageLayout.Content>
    </PageLayout.Root>
  );
}
