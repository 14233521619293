import classNames from 'classnames/bind';
import { Node as ReteNode } from 'rete';

import Node from 'types/node';

import Input from 'pipelines/services/rete/controls/input';

import styles from './Control.module.scss';

const c = classNames.bind(styles);

export type DeploymentReadonlyControlProps = {
  node: Node;
  reteNode: ReteNode;
  property: string;
  value: unknown;
  control: Input;
};

export function DeploymentReadonlyControl({
  node,
  reteNode,
  property,
  control,
  value,
}: DeploymentReadonlyControlProps) {
  // DeploymentFormNode does not render without a component
  const component = node.component!;

  const Displayer = component.getEditorDisplayer(property);

  return (
    <div className={c('control-value')}>
      {Displayer && (
        <Displayer
          node={reteNode}
          property={property}
          value={value}
          type={control.type}
        />
      )}
    </div>
  );
}
