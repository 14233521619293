import classNames from 'classnames/bind';

import { NUMBER_FORMAT } from 'services/number';
import { useCameras } from 'cameras/hooks/useCameras';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useInputStreams } from 'streams/hooks/useInputStreams';
import { useUpdateUsageLimits } from 'organizations/hooks/useUpdateUsageLimits';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';
import { useUser } from 'hooks/useAuth';

import { AppLink } from 'components/AppLink/AppLink';
import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { IconButton } from 'components/IconButton/IconButton';
import { Meter, MeterValue } from 'components/Meter';
import { Text } from 'components/Text/Text';
import {
  UtilizationLimitDialog,
  UtilizationLimitFieldValues,
} from 'organizations/components/UtilizationLimitDialog/UtilizationLimitDialog';

import styles from '../OrganizationUtilization.module.scss';

const c = classNames.bind(styles);

export type LiveStreamsUtilizationCardProp = {
  showInfo?: boolean;
};

export function LiveStreamsUtilizationCard({
  showInfo,
}: LiveStreamsUtilizationCardProp) {
  const user = useUser();

  const { data: inputStreams } = useInputStreams();
  const { data: cameras } = useCameras();
  const { data: currentPlan } = useCurrentPlan();
  const { data: organization } = useCurrentOrganization();

  const {
    isLoading,
    monthlyActiveLiveStreams: { usage, userLimit, planLimit },
  } = useUsageLimits();

  const {
    isLoading: isSaving,
    isSuccess,
    mutateAsync: updateUsageLimits,
  } = useUpdateUsageLimits();

  const hasUsage = Number.isFinite(usage);

  if (!hasUsage || currentPlan === undefined || isLoading) {
    return null;
  }

  const includedInPlan = currentPlan?.included_monthly_active_live_streams;

  async function handleSave({
    shouldLimitUtilization,
    utilizationLimit,
  }: UtilizationLimitFieldValues) {
    if (shouldLimitUtilization && Number.isFinite(utilizationLimit)) {
      const res = await updateUsageLimits({
        monthly_active_live_stream_limit: Number(utilizationLimit),
      });
      return res?.monthly_active_live_stream_limit ?? null;
    } else if (!shouldLimitUtilization) {
      const res = await updateUsageLimits({
        monthly_active_live_stream_limit: null,
      });
      return res?.monthly_active_live_stream_limit ?? null;
    }

    return Promise.reject(new Error('Unable to set utilization limits.'));
  }

  return (
    <Card className={c('item')}>
      <Heading level="4" asChild>
        <h3>Monthly-active live streams</h3>
      </Heading>

      <div className={c('meter-value')}>
        <MeterValue value={usage!} max={includedInPlan} />

        {organization && user?.isBilling(organization.id) && !planLimit && (
          <UtilizationLimitDialog
            item="Monthly-active live streams"
            usage={usage!}
            userLimit={userLimit}
            limitUnit="streams"
            includedInPlan={includedInPlan}
            includedInPlanInfo={`${
              includedInPlan
                ? NUMBER_FORMAT.format(includedInPlan)
                : 'Unlimited'
            } live streams`}
            onSave={handleSave}
            isLoading={isSaving}
            isSuccess={isSuccess}
            trigger={
              <IconButton
                variant="ghost"
                icon="settings"
                label="Manage utilization limits for cloud storage"
                size="small"
              />
            }
          />
        )}
      </div>

      {includedInPlan && <Meter value={usage!} max={includedInPlan} />}

      {showInfo ? (
        <div className={c('item-tags')}>
          <AppLink to="/deploy/streams">
            <p>
              <Icon name="stream" size="small" />
              <Text inline>
                {inputStreams?.total_elements ?? 0} input streams total
              </Text>
            </p>
          </AppLink>

          <AppLink to="/deploy/cameras">
            <p>
              <Icon name="camera" size="small" />
              <Text inline>{cameras?.total_elements ?? 0} cameras total</Text>
            </p>
          </AppLink>
        </div>
      ) : undefined}
    </Card>
  );
}
