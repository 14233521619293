import classnames from 'classnames/bind';

import { WidgetType } from 'types/dashboard_widget';

import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Heading } from 'components/Heading/Heading';
import { Icon, IconType } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './WidgetModal.module.scss';

const c = classnames.bind(styles);

export type WidgetModalHeaderProps = {
  title: string;
  type: WidgetType;
};

const WIDGET_MODAL_HEADER_MAP: Record<
  WidgetType,
  { icon: IconType; description: string }
> = {
  data: {
    icon: 'metrics',
    description: 'Analyze data output from pipeline deployments',
  },
  iframe: {
    icon: 'puzzle',
    description: 'Embed content from a web URL',
  },
  stream: {
    icon: 'stream',
    description: 'Display a video player for a selected stream',
  },
  multistream: {
    icon: 'stream',
    description: 'Display video players for streams matching filter criteria',
  },
  files: {
    icon: 'file',
    description: 'Display a list of files matching filter criteria',
  },
};

export function WidgetModalHeader({ title, type }: WidgetModalHeaderProps) {
  const { icon, description } = WIDGET_MODAL_HEADER_MAP[type];

  return (
    <>
      <div className={c('title')}>
        <Heading level="3">{title}</Heading>
        <ExternalLink href="https://docs.lumeo.com/docs/dashboard">
          Dashboard docs
        </ExternalLink>
      </div>
      <div className={c('header')}>
        <div className={c('type')}>
          <Icon className={c('icon')} name={icon} />
          <Heading className={c('heading')} level="3">
            {type}
          </Heading>
          <Text className={c('info')} align="start" type="paragraph">
            {description}
          </Text>
        </div>
      </div>
    </>
  );
}
