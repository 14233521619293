import { UseQueryOptions } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { LumeoFile } from 'types/file';

import { useAPI } from 'hooks/api/useAPI';
import { useAuthenticatedQuery } from 'hooks/api/useAuthenticatedQuery';

export type UseCheckFilesExistenceResult = Record<LumeoFile['id'], boolean>;

export type UseCheckFilesExistenceOptions = UseQueryOptions<
  UseCheckFilesExistenceResult,
  APIError
> & {
  params: {
    file_ids?: LumeoFile['id'][];
  };
};

export function useCheckFilesExistence({
  params,
  enabled,
  ...options
}: UseCheckFilesExistenceOptions) {
  const { client, applicationID } = useAPI();

  const isEnabled = Boolean(params.file_ids?.length);

  return useAuthenticatedQuery(
    ['files-existence', params],
    async () => {
      const { data } = await client.get(
        `/internal/apps/${applicationID}/files/check_existence`,
        { params }
      );

      return data;
    },
    {
      enabled: enabled === undefined ? isEnabled : isEnabled && enabled,
      ...options,
    }
  );
}
