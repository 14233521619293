import classNames from 'classnames/bind';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useToggle } from '@mantine/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

import { Tag } from 'types/tag';

import { useDeleteTag } from 'tags/hooks/useDeleteTag';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { IconButton } from 'components/IconButton/IconButton';
import { UpdateTagDialog } from 'tags/components/UpdateTagDialog/UpdateTagDialog';

import styles from './TagsTree.module.scss';

const c = classNames.bind(styles);

export type TagsTreeItemContextMenuProps = {
  tag: Tag;
};

export function TagsTreeItemContextMenu({ tag }: TagsTreeItemContextMenuProps) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(search);
  const isActive = searchParams.get('tag') === tag.id;

  const [deleteTag] = useDeleteTag({
    onSuccess() {
      if (!isActive) {
        return;
      }

      navigate({ search: undefined });
    },
  });

  const [isEditing, toggleIsEditing] = useToggle();

  function handleDeleteClick() {
    deleteTag(tag.id);
  }

  return (
    <>
      <ContextMenu
        trigger={
          <IconButton
            className={c('item-options')}
            size="small"
            icon="more-vertical"
            label={`Manage ${tag.name}`}
          />
        }
      >
        <RadixDialog.Root open={isEditing} onOpenChange={toggleIsEditing}>
          <RadixDialog.Trigger asChild>
            <ContextMenuAction onClick={toggleIsEditing}>
              Edit
            </ContextMenuAction>
          </RadixDialog.Trigger>

          <RadixDialog.Portal container={document.body}>
            <RadixDialog.Content>Edit label</RadixDialog.Content>
          </RadixDialog.Portal>
        </RadixDialog.Root>

        <ContextMenuAction
          onClick={handleDeleteClick}
          icon="delete"
          intent="danger"
        >
          Delete
        </ContextMenuAction>
      </ContextMenu>

      <UpdateTagDialog
        tag={tag}
        open={isEditing}
        onOpenChange={toggleIsEditing}
      />
    </>
  );
}
