import { useParams } from 'react-router-dom';

import { useMarketplaceModels } from 'hooks/api/useModels';
import { useMarketplaceModel } from 'hooks/api/useMarketplaceModel';

import { NotFound } from 'views/NotFound/NotFound';

import { ReactComponent as EmptyIllustration } from 'analytics-library/images/empty-models-illustration.svg';
import { ModelParams } from 'analytics-library/types/model_params';
import { MarketplaceModelDetail } from './MarketplaceModelDetail';

export type ModelFieldValues = {
  name: string;
  description?: string;
  capability?: string;
  architecture?: string;
  weights_file?: FileList;
  metadata_file?: FileList | '';
};

export function MarketplaceModelDetailContainer() {
  const { modelID } = useParams<ModelParams>();
  const { isLoading } = useMarketplaceModels();
  const { data: model } = useMarketplaceModel(modelID);

  if (isLoading) {
    return (
      <div className="loading-spinner-wrap is-fullscreen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!model) {
    return (
      <NotFound entity="model" returnTo="/admin" image={EmptyIllustration} />
    );
  }

  return <MarketplaceModelDetail model={model} />;
}
