import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { ApplicationParams } from 'application/types/application_params';

import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';

import { Box } from 'components/Box/Box';
import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import styles from './Search.module.scss';

const c = classNames.bind(styles);

type FilesSearchUpgradeNoticeProps = { onCancel: () => void };

export function FilesSearchUpgradeNotice({
  onCancel,
}: FilesSearchUpgradeNoticeProps) {
  const { applicationID } = useParams<ApplicationParams>();
  const { data: organization, isLoading: isLoadingOrganization } =
    useCurrentOrganization();

  return (
    <Box className={c('upgrade')} variant="primary">
      <Heading level={2}>
        Upgrade your plan to unlock advanced search capabilities
      </Heading>

      <ul className={c('upgrade-list')}>
        <li>
          <Text>
            <Icon name="check" size="small" />
            <span>Search for clips and images using natural language.</span>
          </Text>
        </li>
        <li>
          <Text>
            <Icon name="check" size="small" />
            <span>
              Identify objects, events, scenes, and text within your content.
            </span>
          </Text>
        </li>
        <li>
          <Text>
            <Icon name="check" size="small" />
            <span>Sort by relevance or date.</span>
          </Text>
        </li>
      </ul>

      <div className={c('upgrade-buttons')}>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          loading={isLoadingOrganization}
          to={`/applications/${applicationID}/settings/organization/${organization?.id}/plans`}
          disabled={!organization}
        >
          Compare plans
        </Button>
      </div>
    </Box>
  );
}
