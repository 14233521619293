import classnames from 'classnames/bind';

import {
  NumberInput,
  NumberInputProps,
} from 'components/NumberInput/NumberInput';

import styles from './CropInput.module.scss';

const c = classnames.bind(styles);

export type CropInputFieldProps = Pick<
  NumberInputProps,
  'value' | 'onChange' | 'onError'
> & {
  id: string;
  parentID: string;
};

export function CropInputField({
  parentID,
  id,
  ...props
}: CropInputFieldProps) {
  return (
    <NumberInput
      {...props}
      id={`${parentID}-${id}`}
      classNames={{ root: c('input', id) }}
      min={0}
      size="small"
    />
  );
}
