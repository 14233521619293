import {
  FilterInput,
  FilterOperator,
  TimeRangeInput,
} from '@propeldata/ui-kit';

import { useDashboardFilterParams } from './useDashboardFilterParams';
import { useTimeRangeParams } from './useTimeRangeParams';

export type UseDashboardWidgetParamsOptions = {
  metric: string;
  filters?: FilterInput[];
  timeRange?: TimeRangeInput;
};

export function useDashboardWidgetParams<
  TAdvancedOptions extends Record<string, unknown>,
>({
  metric,
  filters = [],
  timeRange,
  ...options
}: Omit<TAdvancedOptions, 'metric' | 'timeRange' | 'timeZone'> &
  UseDashboardWidgetParamsOptions) {
  const { start, stop, timezone } = useTimeRangeParams();
  const dashboardFilters = useDashboardFilterParams();

  const params = {
    ...options,
    metric: { name: metric },
    timeRange: timeRange ?? { start, stop },
    timeZone: timezone,
    filters,
  };

  if (Object.keys(dashboardFilters).length) {
    const [first, ...and] = Object.entries(dashboardFilters).map<FilterInput>(
      ([column, value]) => ({
        column,
        operator: FilterOperator.Equals,
        value,
      })
    );

    params.filters = [{ ...first, and }, ...filters];
  }

  return params;
}
