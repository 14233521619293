import classnames from 'classnames/bind';
import { Column, usePagination, useRowSelect, useTable } from 'react-table';
import { Pagination } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import APIError from 'types/api_error';
import { PaginatedAPIResponse } from 'types/api';

import { AppEntityObject } from 'hooks/useEntities';
import { Text } from 'components/Text/Text';
import { Loader } from 'components/Loader/Loader';

import {
  TableBulkActions,
  TableBulkActionType,
} from './components/BulkActions/BulkActions';
import { TableRow, TableRowProps } from './TableRow';

import { TableManager } from './Table';
import { deselectReducer } from './service/deselect';
import { getRowId } from './service/row';
import { useCheckboxColumn } from './hooks/useCheckboxColumn';
import styles from './Table.module.scss';

const c = classnames.bind(styles);

export type PaginatedTableProps<T extends AppEntityObject> = {
  id: string;
  label: string;
  className?: string;

  queryResult: UseQueryResult<PaginatedAPIResponse<T> | undefined, APIError>;
  columns: Column<T>[];
  emptyMessage?: JSX.Element;

  bulkActions?: TableBulkActionType[];

  // pageSize needs to match query limit
  pageSize: number;
  page: number | undefined;
  onPageChange: (page: number) => void;

  onRowNavigate?: (element: T) => string;
  onRowClick?: TableRowProps<T>['onClick'];
};

const EMPTY_DATA: unknown[] = [];

export function PaginatedTable<T extends AppEntityObject>({
  id,
  label,
  className,

  queryResult: { isInitialLoading, isSuccess, error, data },
  columns,
  emptyMessage,

  bulkActions,

  pageSize,
  page: currentPage = 1,
  onPageChange,

  onRowNavigate,
  onRowClick,
}: PaginatedTableProps<T>) {
  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,

    page,
    gotoPage,

    dispatch,
    // Toggle visible rows
    toggleAllRowsSelected,

    state: { selectedRowIds },
  } = useTable(
    {
      id,
      columns,
      data: data?.data ?? (EMPTY_DATA as T[]),
      initialState: {
        pageIndex: currentPage - 1,
        pageSize,
      },
      manualPagination: true,
      pageCount: data?.total_pages,
      bulkEdit: Boolean(bulkActions),
      getRowId,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      stateReducer: deselectReducer,
    },
    usePagination,
    useRowSelect,
    useCheckboxColumn
  );

  if (isInitialLoading) {
    return (
      <div className={c('loader')}>
        <Loader size="small" text="Loading..." />
      </div>
    );
  }

  if (isSuccess && data?.total_elements === 0) {
    return emptyMessage || <Text>No results found.</Text>;
  }

  if (error || !data) {
    return <Text>{error?.message || 'An unexpected error occurred.'}</Text>;
  }

  function handleSelectAll() {
    toggleAllRowsSelected(true);
  }

  function handleClearSelection() {
    dispatch({ type: 'deselectAllRows' });
  }

  const hasPagination = data.total_pages > 1;
  const hasSelection =
    Object.keys(selectedRowIds).filter((key) => selectedRowIds[key]).length > 0;
  const hasBulkActions = bulkActions && hasSelection;

  return (
    <div className={c('wrap', className)}>
      <table className={c('table')} {...getTableProps()}>
        <thead className={c('head')}>
          {headerGroups.map((headerGroup) => {
            const { key, ...groupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr {...groupProps} key={key}>
                {headerGroup.headers.map((column) => {
                  const { key: colKey, ...headerProps } =
                    column.getHeaderProps();
                  return (
                    <th
                      {...headerProps}
                      className={c('header', 'label', {
                        autoSize: column.width === 0,
                      })}
                      key={colKey}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                row={row}
                to={onRowNavigate?.(row.original)}
                onClick={onRowClick}
                key={row.id}
              />
            );
          })}
        </tbody>
      </table>

      {(hasPagination || hasBulkActions) && (
        <TableManager>
          {hasPagination && (
            <Pagination
              total={data.total_pages}
              value={currentPage}
              onChange={(page) => {
                onPageChange(page);
                gotoPage(page);
              }}
            />
          )}

          <TableBulkActions
            label={label}
            selectedRowIds={selectedRowIds}
            onClearSelection={handleClearSelection}
            onSelectAll={handleSelectAll}
            totalRows={data.total_elements}
            actions={bulkActions}
          />
        </TableManager>
      )}
    </div>
  );
}
