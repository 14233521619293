import { DesignRoute, NavRoute } from 'application/types/routes';

import { Separator } from 'components/Separator/Separator';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarLink } from 'components/Sidebar/SidebarLink';
import { Text } from 'components/Text/Text';

export function DesignSidebar() {
  return (
    <Sidebar>
      <ul>
        <SidebarLink
          to={`/${NavRoute.DESIGN}/${DesignRoute.PIPELINES}`}
          icon="pipeline"
        >
          Pipelines
        </SidebarLink>
        <SidebarLink
          to={`/${NavRoute.DESIGN}/${DesignRoute.MODELS}`}
          icon="model"
        >
          AI Models
        </SidebarLink>
      </ul>

      <li>
        <Separator />

        <Text>
          <strong>Analytics Library</strong>
        </Text>

        <ul>
          <SidebarLink
            to={`/${NavRoute.DESIGN}/${DesignRoute.SOLUTIONS}`}
            icon="pipeline"
          >
            Solutions
          </SidebarLink>
          <SidebarLink
            to={`/${NavRoute.DESIGN}/${DesignRoute.MARKETPLACE}`}
            icon="model"
          >
            Ready-to-use AI Models
          </SidebarLink>
        </ul>
      </li>
    </Sidebar>
  );
}
