import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@mantine/core';

import { LumeoFile } from 'types/file';

import { Icon } from 'components/Icon/Icon';

import styles from './Thumbnail.module.scss';

const c = classNames.bind(styles);

type CommonProps = {
  className?: string;
  isDeleted?: boolean;
  onError?: () => void;
};

type ThumbnailProps =
  | (Partial<Pick<LumeoFile, 'thumbnail_url' | 'mime_type'>> &
      CommonProps & {
        isLoading: true;
      })
  | (Pick<LumeoFile, 'thumbnail_url' | 'mime_type'> &
      CommonProps & {
        isLoading?: false;
      });

/**
 * Thumbnail for {@link LumeoFile} or {@link FilesSearchResult} with fallback display
 */
export function Thumbnail({
  className,
  mime_type,
  thumbnail_url,
  isLoading,
  isDeleted,
  onError,
}: ThumbnailProps) {
  const [didLoad, setDidLoad] = React.useState(false);

  if (isLoading) {
    return <Skeleton className={c('thumbnail', className)} />;
  }

  if (isDeleted) {
    return (
      <div className={c('thumbnail', 'deleted', className)}>
        <Icon className={c('icon')} name="delete" />
        <span>Pending deletion</span>
      </div>
    );
  }

  const isVideo = mime_type.includes('video');

  if (thumbnail_url) {
    return (
      <div className={c('thumbnail', className)}>
        <img
          className={c('image')}
          src={thumbnail_url}
          onLoad={() => setDidLoad(true)}
          onError={onError}
          loading="lazy"
          alt=""
        />
        {didLoad && isVideo && (
          <div className={c('play', 'theme dark')}>
            <Icon className={c('icon')} name="play" />
          </div>
        )}
      </div>
    );
  }

  // Fallback if file has no thumbnail
  return (
    <div className={c('thumbnail', className)}>
      <Icon className={c('icon')} name={isVideo ? 'play' : 'image'} />
    </div>
  );
}
