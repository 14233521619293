import classNames from 'classnames/bind';

import Stream from 'types/stream';

import { browserSupportsVideoType } from 'services/browser_supports_video_type';
import { useFile } from 'files/hooks/useFile';
import { useHasAccess } from 'hooks/useHasAccess';

import { IconButton } from 'components/IconButton/IconButton';
import { FileActions } from 'files/components/FileActions/FileActions';

import styles from './Table.module.scss';

const c = classNames.bind(styles);

export type FilesOverviewTableActionsCellProps = {
  file: Stream;
  onViewClick: (file: Stream) => void;
  onRenameClick: (fileID: string | undefined) => void;
};

export function FilesOverviewTableActionsCell({
  file: fileStream,
  onViewClick,
  onRenameClick,
}: FilesOverviewTableActionsCellProps) {
  const [hasAccess] = useHasAccess();

  const { fileID } = fileStream;
  const { data: file, isLoading } = useFile(fileID);

  const mimeType = file?.mime_type;
  const canDisplayMedia = mimeType?.includes('video')
    ? browserSupportsVideoType(mimeType)
    : Boolean(mimeType);

  return (
    <div className={c('actions')}>
      {fileStream.isViewable && (
        <IconButton
          icon="eye"
          onClick={() => onViewClick(fileStream)}
          label="View file"
          loading={isLoading}
          disabled={!isLoading && !canDisplayMedia}
          disabledTooltip="Unsupported media type"
        />
      )}
      {hasAccess('deploy_edit') && (
        <FileActions file={fileStream} onRenameClick={onRenameClick} />
      )}
    </div>
  );
}
