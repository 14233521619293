import React from 'react';
import Rete, { Node, Socket } from 'rete';
import {
  NodeData as ReteNodeData,
  WorkerInputs,
  WorkerOutputs,
} from 'rete/types/core/data';
import { createModelSchema, custom, identifier, primitive } from 'serializr';

import { getPropertyValue } from 'types/node';

import { IconType } from 'components/Icon/Icon';

import { DisplayerProps } from '../controls/input';
import { DynamicComponentProperties } from './dynamic_components';
import { DockCategory, NodeReleaseStatus } from './types';

export type ComponentValueDisplayerMap = {
  [property: string]: EditorDisplayer;
};

export type ComponentType = typeof Component & {
  new (): Component;
  input?: { type: string };
};

export type EditorDisplayer = React.ComponentType<DisplayerProps> | null;

type ComponentValueMap = {
  [property: string]: any;
};

type DynamicSocketKeysType = {
  output?: string;
  input?: string;
};

export type DynamicNodeMetadata = {
  [key: string]:
    | true
    | {
        order?: number;
        description?: string;
        type?: string;
      }
    | DynamicNodeMetadata;
};

export default class Component extends Rete.Component {
  // Subclasses can override this to create custom editor displayers for their data
  // <EditorNode> uses editor displayers to display the data in a custom format
  //
  // editorDisplayers is a map from (property name -> React component)
  // where the React component is passed an instance of the node and the property name being displayed
  //
  // If a property has no entry in editorDisplayers, the DefaultDisplayer is used
  // If a property has the entry "null", the property is not displayed on the editor
  static key: string;
  static description?: string;
  protected static editorDisplayers: ComponentValueDisplayerMap = {};
  static icon: IconType;
  static category: DockCategory = 'other';
  static exportType: string;
  /**
   * Human-readable list of available metadata
   */
  static outputMetadata: string[];
  /**
   * List of available metadata for suggestions.
   * Use [brackets] in key to indicate placeholders for Console or the user to fill.
   */
  static metadata?: DynamicNodeMetadata;

  static status: NodeReleaseStatus;
  static release?: string;

  static outputParams?: readonly [Socket, boolean];
  static inputParams?: readonly [Socket, boolean];

  static dynamicSocketKeys?: DynamicSocketKeysType;
  static inheritOutputFromInput = false;

  static properties?: DynamicComponentProperties;

  static getEditorDisplayer(property: string): EditorDisplayer {
    if (typeof this.editorDisplayers[property] === 'undefined') {
      return DefaultDisplayer;
    }
    return this.editorDisplayers[property];
  }

  async createNode(data: ComponentValueMap): Promise<Node> {
    const node = await super.createNode(data);
    node.meta.description = Component.description;
    node.meta.status = Component.status;

    // Initialize properties so we can enumerate them
    node.controls.forEach((control: any, property) => {
      control.props.value = control.initial;
      control.putData(property, control.initial);
    });

    return node;
  }

  async builder(_node: Node) {}

  worker(
    _node: ReteNodeData,
    _inputs: WorkerInputs,
    _outputs: WorkerOutputs,
    ..._args: unknown[]
  ) {}
}

function DefaultDisplayer({ node, property, value, type }: DisplayerProps) {
  const nodeProperty = node.controls?.get(property);
  const props = (nodeProperty as any)?.props || {};
  value = value ?? getPropertyValue(node.data, property);

  switch (type) {
    case 'json': {
      const size = value ? Object.keys(value).length : 0;
      return <strong>{size} fields</strong>;
    }

    case 'boolean': {
      return <strong>{value ? 'enabled' : 'not enabled'}</strong>;
    }

    case 'line':
    case 'polygon':
    case 'multiline':
    case 'list': {
      if (Array.isArray(value)) {
        return <strong>{value.join(';')}</strong>;
      }
    }
  }

  if (value || Number.isFinite(value)) {
    const valueString = String(value);

    if (props.type === 'password') {
      return <strong>set</strong>;
    }

    return (
      <>
        <strong>{valueString}</strong>
        {props.unit && <span>{props.unit}</span>}
      </>
    );
  }

  return <span>unset</span>;
}

function serializeDeserializeSimpleObjects(obj: Object) {
  return typeof obj === 'string' ? JSON.parse(obj) : obj;
}

createModelSchema(Component, {
  id: identifier(),
  name: primitive(),
  icon: primitive(),
  description: primitive(),
  status: primitive(),
  category: primitive(),

  trigger_metadata: custom(
    serializeDeserializeSimpleObjects,
    serializeDeserializeSimpleObjects
  ),

  properties: custom(
    serializeDeserializeSimpleObjects,
    serializeDeserializeSimpleObjects
  ),
  input: custom(
    serializeDeserializeSimpleObjects,
    serializeDeserializeSimpleObjects
  ),
  output: custom(
    serializeDeserializeSimpleObjects,
    serializeDeserializeSimpleObjects
  ),
});
