import classnames from 'classnames/bind';

import { ChartType, DataWidget } from 'types/dashboard_widget';

import { sanitizeMetricFilters } from 'dashboards/components/MetricFiltersInput/sanitize';
import { useTimeRangeParams } from 'dashboards/hooks/useTimeRangeParams';
import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import {
  HeatmapChart,
  HeatmapChartQuery,
} from 'dashboards/charts/HeatmapChart';
import { Counter } from 'dashboards/charts/Counter';
import { PieChart, PieChartProps } from 'dashboards/charts/PieChart';
import {
  PropelLeaderboard,
  PropelLeaderboardProps,
} from 'dashboards/charts/PropelLeaderboard';
import {
  StackedBarChart,
  StackedBarChartQuery,
} from 'dashboards/charts/StackedBarChart';
import { TimeSeries, TimeSeriesProps } from 'dashboards/charts/TimeSeries';

import { DashboardWidgetProps } from '../../types/widget_props';
import styles from './Data.module.scss';

const c = classnames.bind(styles);

export function DashboardData({
  id,
  name,
  chartType,
  query,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<DataWidget<ChartType>>) {
  const { editWidget } = useWidgetModal();

  const { start, stop } = useTimeRangeParams();
  const timeRange = { start, stop };

  const filters = query?.filters
    ? sanitizeMetricFilters(query.filters)
    : undefined;

  const queryOptions = { ...query, timeRange, filters };

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  return (
    <div className={c('wrap')}>
      <div className={c('header')}>
        <Heading level={3}>{name}</Heading>
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>

      <div className={c('chart', chartType)}>
        {chartType === 'timeseries-bar' && (
          <TimeSeries
            query={queryOptions as TimeSeriesProps['query']}
            variant="bar"
          />
        )}
        {chartType === 'timeseries-line' && (
          <TimeSeries
            query={queryOptions as TimeSeriesProps['query']}
            variant="line"
          />
        )}
        {chartType === 'leaderboard-bar' && (
          <PropelLeaderboard
            variant="bar"
            query={queryOptions as PropelLeaderboardProps['query']}
          />
        )}
        {chartType === 'timeseries-bar-stacked' && (
          <StackedBarChart
            variant="stacked"
            query={queryOptions as StackedBarChartQuery}
          />
        )}
        {chartType === 'timeseries-bar-grouped' && (
          <StackedBarChart
            variant="grouped"
            query={queryOptions as StackedBarChartQuery}
          />
        )}
        {chartType === 'timeseries-heatmap' && (
          <HeatmapChart query={queryOptions as HeatmapChartQuery} />
        )}
        {chartType === 'leaderboard-table' && (
          <PropelLeaderboard
            variant="table"
            query={queryOptions as PropelLeaderboardProps['query']}
          />
        )}
        {chartType === 'pie' && (
          <PieChart query={queryOptions as PieChartProps['query']} />
        )}
        {chartType === 'counter' && <Counter query={queryOptions} />}
      </div>
    </div>
  );
}
