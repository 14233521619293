import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { OrganizationParams } from 'organizations/types/organization_params';

import { useHasValidPaymentMethods } from 'organizations/hooks/useHasValidPaymentMethods';
import { useOrganization } from 'hooks/useOrganization';
import { usePricingPlans } from 'organizations/hooks/usePricingPlans';

import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/Text/Text';
import * as Dialog from 'components/Dialog';

import { UpgradePlanForm } from './UpgradePlanForm';
import { PaymentInfoForm, PaymentInfoFormProps } from './PaymentInfoForm';
import styles from './UpgradePlanDialog.module.scss';

const c = classNames.bind(styles);

export type UpgradeDialogProps = Pick<
  Dialog.RootProps,
  'trigger' | 'open' | 'onOpenChange'
> &
  PaymentInfoFormProps;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

export function UpgradePlanDialog({
  planID,
  onSuccess,
  ...dialogProps
}: UpgradeDialogProps) {
  const { organizationID } = useParams<OrganizationParams>();
  const { data: organization } = useOrganization(organizationID);
  const { data: plans } = usePricingPlans();

  const plan = plans?.find(({ id }) => id === planID);

  const { data: hasValidPaymentMethods, isLoading } =
    useHasValidPaymentMethods();

  return (
    <Dialog.Root {...dialogProps} className={c('dialog')} modal>
      <Dialog.Title>
        <Heading level="2" asChild>
          <span>Upgrade {organization?.name || 'organization'} </span>
        </Heading>
      </Dialog.Title>

      <Dialog.Description asChild>
        <div className={c('selected-plan')}>
          <Text>Selected plan</Text>
          <Heading level="3" asChild>
            <strong>Business</strong>
          </Heading>

          {plan && <Text>{plan.price_description}</Text>}
        </div>
      </Dialog.Description>

      {isLoading && <Loader size="small" text="Loading..." />}

      {!isLoading &&
        (hasValidPaymentMethods ? (
          <UpgradePlanForm planID={planID} onSuccess={onSuccess} />
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentInfoForm planID={planID} onSuccess={onSuccess} />
          </Elements>
        ))}
    </Dialog.Root>
  );
}
