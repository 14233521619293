import { CellProps } from 'react-table';

import Deployment from 'types/deployment';
import Gateway from 'types/gateway';
import Pipeline from 'types/pipeline';
import Stream from 'types/stream';

import { useAppEntity } from 'hooks/useEntities';

import { Icon, IconProps } from 'components/Icon/Icon';
import { AppLink } from 'components/AppLink/AppLink';
import { Text } from 'components/Text/Text';

export type AppEntityWithRelatedEntities = {
  pipeline_id?: Pipeline['id'];
  deployment_id?: Deployment['id'];
  gateway_id?: Gateway['id'];
  stream_id?: Stream['id'];
};

export function SourceCell<
  T extends AppEntityWithRelatedEntities = AppEntityWithRelatedEntities,
>({ row: { original } }: CellProps<T>) {
  const { stream_id, deployment_id, pipeline_id, gateway_id } = original;

  let sourceEntity: IconProps['name'] | null = null;
  let sourceLink: string | null = null;

  const stream = useAppEntity('streams', stream_id);
  const deployment = useAppEntity('deployments', deployment_id);
  const pipeline = useAppEntity('pipelines', pipeline_id);
  const gateway = useAppEntity('gateways', gateway_id);

  if (stream_id) {
    sourceEntity = 'stream';
    sourceLink = 'input';
  } else if (deployment_id) {
    sourceEntity = 'deployment';
  } else if (pipeline_id) {
    sourceEntity = 'pipeline';
  } else if (gateway_id) {
    sourceEntity = 'gateway';
  }

  if (!sourceLink) {
    sourceLink = `${sourceEntity}s`;
  }

  const primarySource = stream || deployment || pipeline || gateway;

  if (!sourceEntity || !primarySource) {
    return <Icon name="cloud" size="small" />;
  }

  return (
    <div className="">
      <AppLink to={`/${sourceLink}/${primarySource.id}`}>
        <Icon name={sourceEntity} size="small" />
        <Text inline>{primarySource.name}</Text>
      </AppLink>
    </div>
  );
}
