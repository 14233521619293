import classNames from 'classnames/bind';

import { IconButton } from 'components/IconButton/IconButton';

import { ROICanvasType } from '../types';
import { ROITooltip } from './Tooltip';

import styles from './Toolbar.module.scss';

const c = classNames.bind(styles);

type ROIToolbarProps = {
  type: ROICanvasType;
  isFullscreen: boolean;
  isShowingLabels: boolean;
  selectedShape: string | null;
  onDeleteSelectedShape: () => void;
  onInsertEightPointRectangle: () => void;
  onInsertThreePointLine: () => void;
  onToggleFullscreen: () => void;
  onToggleLabels: () => void;
};

export function ROIToolbar({
  type,
  isFullscreen,
  isShowingLabels,
  selectedShape,
  onDeleteSelectedShape,
  onInsertEightPointRectangle,
  onInsertThreePointLine,
  onToggleFullscreen,
  onToggleLabels,
}: ROIToolbarProps) {
  let typeLabel: string = type || 'shape';

  if (type === 'multiline') {
    typeLabel = 'multi-segment line';
  }

  return (
    <div className={c('toolbar')}>
      {type === 'multiline' && (
        <IconButton
          icon="vector-line"
          label={`Add ${typeLabel}`}
          onClick={onInsertThreePointLine}
          variant="tertiary"
          size="small"
        />
      )}

      {type === 'polygon' && (
        <IconButton
          icon="vector-square"
          label={`Add ${typeLabel}`}
          onClick={onInsertEightPointRectangle}
          variant="tertiary"
          size="small"
        />
      )}

      <IconButton
        icon="delete"
        label={`Delete ${selectedShape}`}
        onClick={onDeleteSelectedShape}
        variant={selectedShape === null ? 'tertiary' : 'primary'}
        intent="danger"
        size="small"
        disabled={selectedShape === null}
        disabledTooltip="Please select a shape"
      />

      <IconButton
        icon={isShowingLabels ? 'eye-off' : 'eye'}
        label={isShowingLabels ? 'Hide labels' : 'Show labels'}
        onClick={onToggleLabels}
        variant="tertiary"
        size="small"
        showLabel
      />

      <div>
        <IconButton
          icon={isFullscreen ? 'collapse' : 'expand'}
          label="Toggle fullscreen"
          onClick={onToggleFullscreen}
          variant="tertiary"
          size="small"
        />

        <ROITooltip typeLabel={typeLabel} />
      </div>
    </div>
  );
}
