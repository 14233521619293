import classNames from 'classnames/bind';

import { IframeWidget } from 'types/dashboard_widget';

import { useWidgetModal } from 'dashboards/components/WidgetModal';

import {
  ContextMenu,
  ContextMenuAction,
} from 'components/ContextMenu/ContextMenu';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';

import { DashboardWidgetProps } from '../../types/widget_props';
import styles from './Iframe.module.scss';

const c = classNames.bind(styles);

export function DashboardIframe({
  id,
  type,
  url,
  name,
  onDelete,
  onDuplicate,
}: DashboardWidgetProps<IframeWidget>) {
  const { editWidget } = useWidgetModal();

  if (type !== 'iframe') {
    return null;
  }

  function handleEditClick() {
    editWidget(id);
  }

  function handleDeleteClick() {
    onDelete(id);
  }

  function handleDuplicateClick() {
    onDuplicate(id);
  }

  return (
    <div className={c('wrap')}>
      <div className={c('header')}>
        <Heading level={3}>
          <span>{name}</span>
        </Heading>
        <ContextMenu
          trigger={
            <IconButton
              icon="more-vertical"
              label="Reveal more actions"
              variant="ghost"
              size="small"
            />
          }
        >
          <ContextMenuAction icon="edit" onClick={handleEditClick}>
            Edit
          </ContextMenuAction>
          <ContextMenuAction icon="copy" onClick={handleDuplicateClick}>
            Duplicate
          </ContextMenuAction>
          <ContextMenuAction
            icon="delete"
            intent="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </ContextMenuAction>
        </ContextMenu>
      </div>
      <iframe
        className={c('iframe-content')}
        src={url}
        sandbox="allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox allow-forms"
        referrerPolicy="no-referrer"
      />
    </div>
  );
}
