import Gateway from 'types/gateway';

import { useGatewaySpecification } from 'gateways/hooks/useGatewaySpecification';

import { EntityList, EntityListItem } from 'components/EntityList';

export type GatewayDetailEntitiesProps = {
  gateway: Gateway;
};

export function GatewayDetailEntities({
  gateway: { id, ip_ext, ip_local, model, version },
}: GatewayDetailEntitiesProps) {
  const { data, isLoading } = useGatewaySpecification(id);

  function renderProductOrModel() {
    if (!data && isLoading) {
      return <EntityListItem icon="gateway" label="" loading />;
    }

    if (data?.specification.product) {
      return (
        <EntityListItem
          icon="gateway"
          label="Product"
          value={data.specification.product}
        />
      );
    }

    return (
      <EntityListItem icon="gateway" label="Model" value={model ?? 'unknown'} />
    );
  }

  return (
    <EntityList>
      <EntityListItem
        icon="global"
        label="IP address"
        value={ip_ext ?? ip_local}
      />
      {renderProductOrModel()}
      <EntityListItem icon="logo" label="Lumeo version" value={version} />
    </EntityList>
  );
}
