import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { OrganizationParams } from 'organizations/types/organization_params';

import { useOrganization } from 'hooks/useOrganization';

import { FormMessage } from 'components/FormMessage/FormMessage';
import { Heading } from 'components/Heading/Heading';
import { Loader } from 'components/Loader/Loader';
import { NotFound } from 'views/NotFound/NotFound';
import { Pill } from 'components/Pill/Pill';
import { Text } from 'components/Text/Text';
import * as PageLayout from 'components/PageLayout';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useUsageStatistics } from 'organizations/hooks/useUsageStatistics';

import { WorkspaceUtilizationCard } from './cards/WorkspaceUtilizationCard';
import { CloudStorageUtilizationCard } from './cards/CloudStorageUtilizationCard';
import { LiveStreamsUtilizationCard } from './cards/LiveStreamsUtilizationCard';
import { RecordedUtilizationCard } from './cards/RecordedUtilizationCard';
import styles from './OrganizationUtilization.module.scss';

const c = classNames.bind(styles);

export function OrganizationUtilization() {
  const { organizationID } = useParams<OrganizationParams>();
  const { data: organization } = useOrganization(organizationID);

  const { data: usageStatistics, isLoading } = useUsageStatistics();

  const { data: currentPlan } = useCurrentPlan();

  if (!organization) {
    return <NotFound entity="organization" returnTo={'/organizations'} />;
  }

  return (
    <PageLayout.Root size="small">
      <PageLayout.Header className={c('header')}>
        <PageLayout.Container>
          <div>
            <Text>
              <Link to="/organizations">Organization</Link>
            </Text>
            <Heading level="1">{organization?.name}</Heading>

            {currentPlan && (
              <div className={c('current-plan-info')}>
                <VisuallyHidden>
                  <h2>Current plan</h2>
                </VisuallyHidden>
                <FormMessage intent="info" icon="plan">
                  <strong>{currentPlan.name}</strong>
                  <Text type="paragraph">{currentPlan.description}</Text>
                </FormMessage>
              </div>
            )}

            {/* Custom plan */}
            {currentPlan === null && (
              <div className={c('current-plan-info')}>
                <VisuallyHidden>
                  <h2>Current plan</h2>
                </VisuallyHidden>

                <FormMessage intent="info" icon="plan">
                  <Pill intent="info">Custom plan</Pill>
                </FormMessage>
              </div>
            )}
          </div>
        </PageLayout.Container>
      </PageLayout.Header>
      <PageLayout.Content>
        <PageLayout.Container>
          <section>
            <VisuallyHidden>
              <h2>Utilization</h2>
            </VisuallyHidden>

            {isLoading && (
              <Loader size="small" text="Loading utilization data..." />
            )}

            {!isLoading && !usageStatistics && (
              <Text align="center">
                Utilization data currently unavailable.
                <br />
                Please try again later.
              </Text>
            )}

            <ul className={c('card-grid')}>
              <li className={c('card-grid-item')}>
                <WorkspaceUtilizationCard />
              </li>
              <li className={c('card-grid-item')}>
                <CloudStorageUtilizationCard />
              </li>
              <li className={c('card-grid-item')}>
                <LiveStreamsUtilizationCard />
              </li>
              <li className={c('card-grid-item')}>
                <RecordedUtilizationCard />
              </li>
            </ul>
          </section>
        </PageLayout.Container>
      </PageLayout.Content>
    </PageLayout.Root>
  );
}
