import classnames from 'classnames/bind';

import Camera from 'types/camera';

import { Separator } from 'components/Separator/Separator';

import { CameraDetailNetworkSettings } from './NetworkSettings';
import { CapabilitySettings } from './CapabilitySettings';
import { CredentialSettings } from './CredentialSettings';
import { GatewaySettings } from './GatewaySettings';
import { UniversalBridgeSettings } from './UniversalBridgeSettings';
import styles from './CameraDetailSettings.module.scss';

const c = classnames.bind(styles);

type CameraDetailSettingsProps = {
  camera: Camera;
};

export function CameraDetailSettings({ camera }: CameraDetailSettingsProps) {
  return (
    <div className={c('settings')}>
      {camera.isIP() && (
        <>
          <CredentialSettings camera={camera} />
          <Separator />
        </>
      )}
      {camera.isIP() && (
        <>
          <GatewaySettings camera={camera} />
          <Separator />
        </>
      )}
      <CameraDetailNetworkSettings camera={camera} />
      <Separator />
      <UniversalBridgeSettings camera={camera} />
      {camera.isUSB() && Boolean(camera.capabilities) && (
        <>
          <Separator />
          <CapabilitySettings camera={camera} />
        </>
      )}
    </div>
  );
}
