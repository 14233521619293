import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';

import { Button } from 'components/Button/Button';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { Field } from 'components/Field/Field';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import * as Dialog from 'components/Dialog';

import styles from './TableNameCell.module.scss';

const c = classNames.bind(styles);

type RenameDialogFieldValues = {
  name: string;
};

export type RenameDialogProps = Pick<
  Dialog.RootProps,
  'open' | 'defaultOpen'
> & {
  entity: string;
  defaultValue?: string;
  onSave: (name: string) => void;
  onCancel?: () => void;
};

export function RenameDialog({
  entity,
  defaultValue,
  onSave,
  onCancel,
  ...props
}: RenameDialogProps) {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<RenameDialogFieldValues>({
    defaultValues: {
      name: defaultValue,
    },
  });

  function handleOpenChange(open: boolean) {
    reset();

    if (!open) {
      onCancel?.();
    }
  }

  function onSubmit({ name }: RenameDialogFieldValues) {
    onSave(name);
  }

  return (
    <Dialog.Root
      {...props}
      className={c('dialog')}
      onOpenChange={handleOpenChange}
    >
      <Dialog.Title asChild>
        <Heading level="2">Rename {entity}</Heading>
      </Dialog.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field id="entity-name" label="Name" error={errors.name} required>
          <Input
            {...register('name', { required: 'Please choose a name.' })}
            id="entity-name"
            type="text"
            autoComplete="off"
            spellCheck="false"
            autoFocus
          />
        </Field>

        <ButtonGroup align="end">
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </ButtonGroup>
      </form>
    </Dialog.Root>
  );
}
