import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { DimensionInput, FilterInput, Sort } from '@propeldata/ui-kit';
import { Pie } from 'react-chartjs-2';

import { LeaderboardQueryVariables } from 'graphql/queries/leaderboard';

import { useChartColors } from 'dashboards/hooks/useChartColors';
import { useDashboardWidgetParams } from 'dashboards/hooks/useDashboardWidgetParams';
import { useLeaderboard } from 'dashboards/hooks/useLeaderboard';

import { ChartError } from './components/ChartError';
import { ChartLoader } from './components/ChartLoader';
import { WIDGET_REFETCH_INTERVAL } from '../services/interval';
import { hslaToString } from '../services/color';

ChartJS.register(ArcElement, Legend, Title, Tooltip);

const EMPTY: string[] = [];

export type PieChartProps = {
  query: {
    metric: string;
    dimensions: DimensionInput[];
    rowLimit: number;
    sort: Sort;
    filters?: FilterInput[];
  };
};

export function PieChart({ query }: PieChartProps) {
  const params = useDashboardWidgetParams<LeaderboardQueryVariables>(query);

  const {
    data,
    isLoading: isLoadingLeaderboard,
    error,
  } = useLeaderboard(params, {
    refetchInterval: WIDGET_REFETCH_INTERVAL,
    keepPreviousData: true,
  });

  const labels = data?.leaderboard.rows.map(([label]) => label) || EMPTY;

  const { data: colors, isLoading: isLoadingColors } = useChartColors(labels);

  const isLoading = isLoadingLeaderboard || isLoadingColors;

  if (error) {
    return (
      <ChartError
        error={{ title: 'No data', body: 'Failed to fetch values.' }}
      />
    );
  }

  if (!data && isLoading) {
    return <ChartLoader />;
  }

  if (!data || !colors) {
    return null;
  }

  const { leaderboard } = data;
  const values = leaderboard.rows.map(([_, value]) => value);
  const total = values.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  );

  function formatTooltipWithPercentage({
    formattedValue,
    parsed,
  }: TooltipItem<'pie'>) {
    const percentage = ((parsed / total) * 100).toFixed(2);

    return `${formattedValue} - ${percentage}%`;
  }

  const datasets = [
    {
      data: values,
      backgroundColor: colors.map(hslaToString),
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Total: ${total}`,
        position: 'bottom' as const,
      },
      tooltip: { callbacks: { label: formatTooltipWithPercentage } },
    },
  };

  return <Pie data={{ labels, datasets }} options={options} />;
}
