import { NodeEditor } from 'rete';

import { InputProps } from 'components/Input/Input';
import ReteInput, {
  ReteInputParams,
  ReteInputProps,
} from 'pipelines/services/rete/controls/input';

import { PasswordInput } from 'components/PasswordInput/PasswordInput';

type RetePasswordInputParams = ReteInputParams & {
  autoComplete?: InputProps['autoComplete'];
  spellCheck?: InputProps['spellCheck'];
};

type RetePasswordInputProps = ReteInputProps;

export default class RetePasswordInput extends ReteInput<RetePasswordInputProps> {
  constructor(
    editor: NodeEditor,
    key: string,
    { autoComplete, spellCheck, ...params }: RetePasswordInputParams
  ) {
    super(editor, key, { ...params });

    this.props.autoComplete = autoComplete;
    this.props.spellCheck = spellCheck;
    this.props.type = 'password';
  }

  get component() {
    return RetePasswordInputComponent;
  }
}

function RetePasswordInputComponent({
  onChange,
  ...props
}: RetePasswordInputProps) {
  return <PasswordInput {...props} onValueChange={onChange} />;
}
