import React from 'react';
import classNames from 'classnames/bind';
import { DateTime } from 'luxon';

import { FilesSearchResult } from 'types/files_search_result';
import { LumeoFile } from 'types/file';
import { VideoSource } from 'hooks/api/useVideoSources';

import { formatPretty } from 'services/string';
import { useModal } from 'hooks/useModal';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { Pill } from 'components/Pill/Pill';
import { Popover } from 'components/Popover/Popover';
import { Text } from 'components/Text/Text';
import { TextAppLink } from 'components/TextAppLink/TextAppLink';
import { Thumbnail } from 'components/Thumbnail/Thumbnail';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { FILES_SEARCH_ICONS_MAP } from '../../services/icons_map';
import { useFilesSearchState } from './search_context';
import styles from './Search.module.scss';

const c = classNames.bind(styles);

type FilesSearchItemProps = Pick<
  LumeoFile,
  'id' | 'name' | 'mime_type' | 'thumbnail_url' | 'stream_id' | 'created_at'
> &
  Partial<Pick<FilesSearchResult, 'events' | 'objects'>> & {
    source?: VideoSource;
    isDeleted: boolean;
  };

export function FilesSearchItem({
  id,
  name,
  thumbnail_url,
  mime_type,
  created_at,
  events = [],
  objects = [],
  stream_id,
  source,
  isDeleted,
}: FilesSearchItemProps) {
  const { selectedFileIds, dispatch } = useFilesSearchState();

  const { open } = useModal();

  const isChecked = selectedFileIds.includes(id);

  function handleCheckboxChange({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (checked) {
      dispatch({ type: 'add', ids: [id] });
    } else {
      dispatch({ type: 'remove', id });
    }
  }

  const [firstEvent, ...moreEvents] = events;

  const objectsWithIcon = objects.filter(
    (object) => FILES_SEARCH_ICONS_MAP[object]
  );
  const objectsWithoutIcon = objects.filter(
    (object) => !FILES_SEARCH_ICONS_MAP[object]
  );

  return (
    <div className={c('item', { deleted: isDeleted })}>
      {!isDeleted && (
        <Checkbox
          id={`bulk_${id}`}
          className={c('item-checkbox', { checked: isChecked })}
          checked={isChecked}
          onChange={handleCheckboxChange}
          aria-label={`Select file ${name}`}
        />
      )}

      <button
        className={c('item-view-button')}
        onClick={() =>
          open('files-search', {
            defaultFile: { id, created_at },
            defaultStreamId: stream_id,
          })
        }
        disabled={isDeleted}
      >
        <Thumbnail
          mime_type={mime_type}
          thumbnail_url={thumbnail_url}
          isDeleted={isDeleted}
        />
        <Text size="small">
          {DateTime.fromISO(created_at).toLocaleString(DateTime.DATETIME_MED)}
        </Text>

        <Heading className={c('item-name')} level="3">
          {name}
        </Heading>
      </button>

      {source && (
        <div className={c('item-source')}>
          <Icon name={source.source_type} />
          <TextAppLink to={`/deploy/${source.source_type}s/${source.id}`}>
            {source.name}
          </TextAppLink>
        </div>
      )}

      {events.length ? (
        <div className={c('item-events')}>
          <Pill>{formatPretty(firstEvent)}</Pill>
          {moreEvents.length > 0 && (
            <Popover
              trigger={
                <Button size="xsmall" variant="tertiary">
                  +{moreEvents.length}
                </Button>
              }
            >
              <ul>
                {moreEvents.map((event) => (
                  <li key={event}>{formatPretty(event)}</li>
                ))}
              </ul>
            </Popover>
          )}
        </div>
      ) : null}

      <div className={c('item-icons')}>
        {objectsWithIcon.map((object) => (
          <Tooltip content={object} key={object}>
            <Icon name={FILES_SEARCH_ICONS_MAP[object]} />
          </Tooltip>
        ))}

        {objectsWithoutIcon.length > 0 && (
          <Tooltip content={objectsWithoutIcon.join(', ')}>
            <span className={c('item-more-objects')}>
              +{objectsWithoutIcon.length}
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
