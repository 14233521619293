import classNames from 'classnames/bind';
import { filesize } from 'filesize';

import { useCurrentPlan } from 'organizations/hooks/useCurrentPlan';
import { useCurrentOrganization } from 'hooks/api/useCurrentOrganization';
import { useUpdateUsageLimits } from 'organizations/hooks/useUpdateUsageLimits';
import { useUsageLimits } from 'organizations/hooks/useUsageLimits';
import { useUser } from 'hooks/useAuth';

import { Card } from 'components/Card/Card';
import { FileSizeInput } from 'components/FileSizeInput/FileSizeInput';
import { Heading } from 'components/Heading/Heading';
import { IconButton } from 'components/IconButton/IconButton';
import { Meter, MeterValue } from 'components/Meter';
import { NumberInputProps } from 'components/NumberInput/NumberInput';
import {
  UtilizationLimitDialog,
  UtilizationLimitFieldValues,
} from 'organizations/components/UtilizationLimitDialog/UtilizationLimitDialog';

import styles from '../OrganizationUtilization.module.scss';

const c = classNames.bind(styles);

function FileSizeInputWrap({ unit, ...props }: NumberInputProps) {
  return <FileSizeInput {...props} displayUnit="TiB" />;
}

export function CloudStorageUtilizationCard() {
  const user = useUser();

  const { data: currentPlan } = useCurrentPlan();
  const {
    isLoading,
    cloudStorageBytes: { usage, userLimit, planLimit },
  } = useUsageLimits();
  const { data: organization } = useCurrentOrganization();

  const {
    isLoading: isSaving,
    isSuccess,
    mutateAsync: updateUsageLimits,
  } = useUpdateUsageLimits();

  const hasUsage = Number.isFinite(usage);

  if (!hasUsage || currentPlan === undefined || isLoading || !organization) {
    return null;
  }

  const includedInPlan = currentPlan?.included_cloud_storage_bytes;

  async function handleSave({
    shouldLimitUtilization,
    utilizationLimit,
  }: UtilizationLimitFieldValues) {
    if (shouldLimitUtilization && Number.isFinite(utilizationLimit)) {
      const res = await updateUsageLimits({
        cloud_storage_bytes_limit: Number(utilizationLimit),
      });
      return res?.cloud_storage_bytes_limit ?? null;
    } else if (!shouldLimitUtilization) {
      const res = await updateUsageLimits({
        cloud_storage_bytes_limit: null,
      });
      return res?.cloud_storage_bytes_limit ?? null;
    }

    return Promise.reject(new Error('Unable to set utilization limits.'));
  }

  return (
    <Card className={c('item')}>
      <Heading level="4" asChild>
        <h3>Cloud storage</h3>
      </Heading>

      <div className={c('meter-value')}>
        <MeterValue value={usage!} max={includedInPlan} unit="filesize" />

        {user?.isBilling(organization.id) && !planLimit && (
          <UtilizationLimitDialog
            item="Cloud storage"
            unit="filesize"
            usage={usage!}
            userLimit={userLimit}
            limitUnit="TiB"
            includedInPlan={includedInPlan}
            includedInPlanInfo={
              includedInPlan
                ? filesize(includedInPlan, { base: 2 })
                : 'Unlimited'
            }
            onSave={handleSave}
            isLoading={isSaving}
            isSuccess={isSuccess}
            trigger={
              <IconButton
                variant="ghost"
                icon="settings"
                label="Manage utilization limits for cloud storage"
                size="small"
              />
            }
            InputComponent={FileSizeInputWrap}
          />
        )}
      </div>

      {includedInPlan && <Meter value={usage!} max={includedInPlan} />}
    </Card>
  );
}
